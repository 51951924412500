import React, { useEffect } from "react";
import PageDescription from "../../layout/page-description";
import UserCompanyPicker from "../../layout/user-company-picker";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import companyService from "../../services/company.service";
import ercEntityService, { ErcEntity } from "../../services/erc-entity.service";
import PrintPre from "../../layout/print-pre";
import TableNeogen, { Formatter } from "../../layout/table-neogen";
import userCompanyService from "../../services/user-company.service";
import userService from "../../services/user.service";
import ModalDialog from "../../layout/modal-dialog";
import InputControlled from "../../layout/input-controlled";
import dealsService from "../../services/deals.service";
import { id } from "date-fns/locale";
import { set } from "lodash";
import SelectNeoGen from "../../layout/select-neogen";
import dealTagOptionsService from "../../services/deal-tag-options.service";
import ButtonNeoGen from "../../layout/button-neogen";
// import { Button } from "react-bootstrap";
import dealTagService from "../../services/deal-tag.service";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import { CursorArrowRaysIcon, EnvelopeOpenIcon, UsersIcon } from "@heroicons/react/24/outline";
import SwalNeogenFire from "../../layout/swal-neogen";
import { Modal } from "react-bootstrap";
// import { BasicLiabilities } from "./reports/basic-liabilities";
import ReactPDF, { PDFViewer } from "@react-pdf/renderer";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { CSVDownload, CSVLink } from "react-csv";
const csvData = [
    ["firstname", "lastname", "email"],
    ["Ahmed", "Tomi", "ah@smthing.co.com"],
    ["Raed", "Labes", "rl@smthing.co.com"],
    ["Yezzi", "Min l3b", "ymin@cocococo.com"],
];
const USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});
type Filter = {
    field: string;
    value: string;
    isOptional: boolean;
};
const stats = [
    { id: 1, name: "Total Subscribers", stat: "71,897", icon: UsersIcon, change: "122", changeType: "increase" },
    { id: 2, name: "Avg. Open Rate", stat: "58.16%", icon: EnvelopeOpenIcon, change: "5.4%", changeType: "increase" },
    {
        id: 3,
        name: "Avg. Click Rate",
        stat: "24.57%",
        icon: CursorArrowRaysIcon,
        change: "3.2%",
        changeType: "decrease",
    },
];
function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
}
enum ReportTypes {
    FullLiabilityReport = 1,
    ClientMoneyBackReport = 2,
    BasedOnMoneyReceived = 3,
    RevenueCollectedQ3 = 4,
    PotentialLiabilityReport = 5,
    PotentialLiabilitiesForClientsPaid = 6,
    BasicLiabilityReporting = 7,
    OtherLiabilities = 8,
    PayLaterDeals = 9,
    BadDeals = 10,
    PEO = 11,
    MoneyStillOwedOnDealsWeHaveBeenPaidOn = 12,
}

const headers = [
    { label: "Client Name", key: "companyName" },
    { label: "High Risk Issues", key: "highRiskIssues" },
    { label: "Is The Company Under A Peo", key: "isAPeo" },
    { label: "Deal Pay Type", key: "dealPayType" },
    { label: "Fee Percentage", key: "feePercentage" },
    { label: "Deal Pay Status", key: "dealPayStatus" },
    { label: "Calculated Potential Income", key: "Calculated Potential Income" },
    { label: "Removed For Bad Quarters", key: "Removed For Bad Quarters" },
    { label: "Removed For Q3 2021", key: "Removed For Q3 2021" },
    { label: "Removed For Outstanding Fees", key: "Removed For Outstanding Fees" },
    { label: "Removed For Owed Commissions", key: "Removed For Owed Commissions" },
    { label: "Was The Deal Financed", key: "wasTheDealFinanced" },
    { label: "Did The IRS Issue A Refund Full Partial Or None", key: "didTheIrsIssueARefundFullPartialOrNone" },
    {
        label: "ERC Amount Filed",
        key: "clientRefundAmountBasedOn_941XsBeforeInterest",
    },
    { label: "Total Clear ERC Fees", key: "clearErcFees" },
    { label: "Total Amount Collected", key: "amountPaid" },
    { label: "Total Liability For 5 Years", key: "Total Liability For 5 Years" },
    { label: "Amount Still Owed", key: "amountStillOwed" },
    { label: "Q2 2020 941 X Amount Filed", key: "q2_2020_941XAmountFiled" },
    { label: "Did IRS Receive Q2 2020", key: "didIrsRecieveQ2_2020" },
    { label: "Any Issues With Q2 2020 IRS Transcripts", key: "anyIssuesWithQ2_2020IrsTranscripts" },
    { label: "Amount On File With The IRS For Q2 2020", key: "amountOnFileWithTheIrsForQ2_2020" },
    { label: "Did The IRS Issue A Refund For Q2 2020", key: "didTheIrsIssueARefundForQ2_2020" },
    { label: "Amount Of Refund Issued For Q2 2020", key: "amountOfRefundIssuedForQ2_2020" },
    { label: "Q3 2020 941 X Amount Filed", key: "q3_2020_941XAmountFiled" },
    { label: "Did IRS Receive Q3 2020", key: "didIrsRecieveQ3_2020" },
    { label: "Issues With Q3 2020 IRS Transcripts", key: "issuesWithQ3_2020IrsTranscripts" },
    { label: "Amount On File With The IRS For Q3 2020", key: "amountOnFileWithTheIrsForQ3_2020" },
    { label: "Did The IRS Issue A Refund For Q3 2020", key: "didTheIrsIssueARefundForQ3_2020" },
    { label: "Amount Of Refund Issued For Q3 2020", key: "amountOfRefundIssuedForQ3_2020" },
    { label: "Q4 2020 941 X Amount Filed", key: "q4_2020_941XAmountFiled" },
    { label: "Did IRS Receive Q4 2020", key: "didIrsRecieveQ4_2020" },
    { label: "Any Issues With Q4 2020 IRS Transcripts", key: "anyIssuesWithQ4_2020IrsTranscripts" },
    { label: "Amount On File With The IRS For Q4 2020", key: "amountOnFileWithTheIrsForQ4_2020" },
    { label: "Did The IRS Issue A Refund For Q4 2020", key: "didTheIrsIssueARefundForQ4_2020" },
    { label: "Amount Of Refund Issued For Q4 2020", key: "amountOfRefundIssuedForQ4_2020" },
    { label: "Q1 2021 941 X Amount Filed", key: "q1_2021_941XAmountFiled" },
    { label: "Did IRS Receive Q1 2021", key: "didIrsRecieveQ1_2021" },
    { label: "Any Issues With Q1 2021 IRS Transcripts", key: "anyIssuesWithQ1_2021IrsTranscripts" },
    { label: "Amount On File With The IRS For Q1 2021", key: "amountOnFileWithTheIrsForQ1_2021" },
    { label: "Did The IRS Issue A Refund For Q1 2021", key: "didTheIrsIssueARefundForQ1_2021" },
    { label: "Amount Of Refund Issued For Q1 2021", key: "amountOfRefundIssuedForQ1_2021" },
    { label: "Q2 2021 941 X Amount Filed", key: "q2_2021_941XAmountFiled" },
    { label: "Did IRS Receive Q2 2021", key: "didIrsRecieveQ2_2021" },
    { label: "Any Issues With Q2 2021 IRS Transcripts", key: "anyIssuesWithQ2_2021IrsTranscripts" },
    { label: "Amount On File With The IRS For Q2 2021", key: "amountOnFileWithTheIrsForQ2_2021" },
    { label: "Did The IRS Issue A Refund For Q2 2021", key: "didTheIrsIssueARefundForQ2_2021" },
    { label: "Amount Of Refund Issued For Q2 2021", key: "amountOfRefundIssuedForQ2_2021" },
    { label: "Did We File Q3 941 X", key: "didWeFileQ3_941X" },
    { label: "Q3 2021 941 X Amount Filed", key: "q3_2021_941XAmountFiled" },
    { label: "Did IRS Receive Q3 2021", key: "didIrsRecieveQ3_2021" },
    { label: "Any Issues With Q3 2021 IRS Transcripts", key: "anyIssuesWithQ3_2021IrsTranscripts" },
    { label: "Amount On File With The IRS For Q3 2021", key: "amountOnFileWithTheIrsForQ3_2021" },
    { label: "Did The IRS Issue A Refund For Q3 2021", key: "didTheIrsIssueARefundForQ3_2021" },
    { label: "Amount Of Refund Issued For Q3 2021", key: "amountOfRefundIssuedForQ3_2021" },
    { label: "Was There A Q3 Disallowance", key: "wasThereAQ3Disallowance" },
    { label: "Amount Still Owed Without Q3", key: "amountStillOwedWithoutQ3" },
    { label: "Q3 Money That We May Have To Refund", key: "q3MoneyThatWeMayHaveToRefund" },
    { label: "Q4 2021 941 X Amount Filed", key: "q4_2021_941XAmountFiled" },
    { label: "Did IRS Receive Q4 2021", key: "didIrsRecieveQ4_2021" },
    { label: "Any Issues With Q4 2021 IRS Transcripts", key: "anyIssuesWithQ4_2021IrsTranscripts" },
    { label: "Q2 2020 Disallowance", key: "Q2 2020 Disallowance" },
    { label: "Q3 2020 Disallowance", key: "Q3 2020 Disallowance" },
    { label: "Q4 2020 Disallowance", key: "Q4 2020 Disallowance" },
    { label: "Q1 2021 Disallowance", key: "Q1 2021 Disallowance" },
    { label: "Q2 2021 Disallowance", key: "Q2 2021 Disallowance" },
    { label: "Q3 2021 Disallowance", key: "Q3 2021 Disallowance" },
    { label: "Q4 2021 Disallowance", key: "Q4 2021 Disallowance" },

    { label: "Amount On File With The IRS For Q4 2021", key: "amountOnFileWithTheIrsForQ4_2021" },
    { label: "Did The IRS Issue A Refund For Q4 2021", key: "didTheIrsIssueARefundForQ4_2021" },
    { label: "Amount Of Refund Issued For Q4 2021", key: "amountOfRefundIssuedForQ4_2021" },
    { label: "Total Refunds Issued", key: "totalRefundsIssued" },
    { label: "Is There A Tax Liability", key: "isThereATaxLiability" },
    {
        label: "How Much Of ERC Credit Transferred Due To Tax Liability",
        key: "howMuchOfErcCreditTransferredDueToTaxLiability",
    },
    { label: "Is There Monitoring", key: "isThereMonitoring" },
    { label: "What Type Of Monitoring", key: "whatTypeOfMonitoring" },
    { label: "Affiliate Group Team", key: "affliateGroupTeam" },
    { label: "Affiliate On Account", key: "affiliateOnAccount" },
    { label: "Main Affiliate Name", key: "mainAffiliateName" },
    { label: "Main Affiliate Percentage", key: "mainAffiliatePercentage" },
    {
        label: "Main Affiliate Commissions Based On Amount Refunded",
        key: "mainAffiliateCommissionsBasedOnAmountRefunded",
    },
    {
        label: "Total Main Affiliate Commission Due Based On Fees Already Paid",
        key: "totalMainAffiliateCommissionDueBasedOnFeesAlreadyPaid",
    },
    { label: "Current Paid Main Affiliate Commission", key: "currentPaidMainAffiliateCommission" },
    { label: "Current Still Owed Main Affiliate Commission", key: "currentStillOwedMainAffiliateCommission" },
    {
        label: "Total Main Affiliate Commission Based On Overall Clear ERC Fees",
        key: "totalMainAffiliateCommissionBasedOnOverallClearErcFees",
    },
    {
        label: "Potential Future Still Owed Main Affiliate Commission",
        key: "potentialFutureStillOwedMainAffiliateCommission",
    },
    { label: "Sub Affiliate Name", key: "subAffiliateName" },
    { label: "Sub Affiliate Percentage", key: "subAffiliatePercentage" },
    { label: "Sub Affiliate Commission Based On Amount Refunded", key: "subAffiliateCommissionBasedOnAmountRefunded" },
    {
        label: "Total Sub Affiliate Commission Due Based On Fees Already Paid",
        key: "totalSubAffiliateCommissionDueBasedOnFeesAlreadyPaid",
    },
    { label: "Current Paid Sub Affiliate Commission", key: "currentPaidSubAffiliateCommission" },
    { label: "Current Still Owed Sub Affiliate Commission", key: "currentStillOwedSubAffiliateCommission" },
    {
        label: "Total Sub Affiliate Commission Based On Overall Clear ERC Fees",
        key: "totalSubAffiliateCommissionBasedOnOverallClearErcFees",
    },
    {
        label: "Potential Future Still Owed Sub Affiliate Commission",
        key: "potentialFutureStillOwedSubAffiliateCommission",
    },
    { label: "Mike's Related Commission Percentage", key: "mikesRelatedCommissionPercentage" },
    { label: "Amount Due To Mike Based On Amount Refunded", key: "amountDueToMikeBasedOnAmountRefunded" },
    {
        label: "Total Mike's Commission Due Based On Fees Already Paid",
        key: "totalMikesCommissionDueBasedOnFeesAlreadyPaid",
    },
    { label: "Current Paid Mike's Commission", key: "currentPaidMikesCommission" },
    { label: "Current Still Owed Mike's Commission", key: "currentStillOwedMikesCommission" },
    {
        label: "Total Mike's Commission Based On Overall Clear ERC Fees",
        key: "totalMikesCommissionBasedOnOverallClearErcFees",
    },
    {
        label: "Potential Future Still Owed Mike's Affiliate Commission",
        key: "potentialFutureStillOwedMikesAffiliateCommission",
    },
    { label: "Jacob's Related Commission Percentage", key: "jacobsRelatedCommissionPercentage" },
    { label: "Amount Due To Jacob Based On Amount Refunded", key: "amountDueToJacobBasedOnAmountRefunded" },
    {
        label: "Total Jacob's Commission Due Based On Fees Already Paid",
        key: "totalJacobsCommissionDueBasedOnFeesAlreadyPaid",
    },
    { label: "Current Paid Jacob's Commission", key: "currentPaidJacobsCommission" },
    { label: "Current Still Owed Jacob's Commission", key: "currentStillOwedJacobsCommission" },
    {
        label: "Total Jacob's Commission Based On Overall Clear ERC Fees",
        key: "totalJacobsCommissionBasedOnOverallClearErcFees",
    },
    {
        label: "Potential Future Still Owed Jacob's Affiliate Commission",
        key: "potentialFutureStillOwedJacobsAffiliateCommission",
    },
    { label: "Tom's Related Commission Percentage", key: "tomsRelatedCommissionPercentage" },
    { label: "Amount Due To Tom Based On Amount Refunded", key: "amountDueToTomBasedOnAmountRefunded" },
    {
        label: "Total Tom's Commission Due Based On Fees Already Paid",
        key: "totalTomsCommissionDueBasedOnFeesAlreadyPaid",
    },
    { label: "Current Paid Tom's Commission", key: "currentPaidTomsCommission" },
    { label: "Current Still Owed Tom's Commission", key: "currentStillOwedTomsCommission" },
    {
        label: "Total Tom's Commission Based On Overall Clear ERC Fees",
        key: "totalTomsCommissionBasedOnOverallClearErcFees",
    },
    {
        label: "Potential Future Still Owed Tom's Affiliate Commission",
        key: "potentialFutureStillOwedTomsAffiliateCommission",
    },
    { label: "Total Commission Due", key: "totalCommissionDue" },
    { label: "Total Paid Commission", key: "totalPaidCommission" },
    { label: "Total Current Still Owed Commission", key: "totalCurrentStillOwedCommission" },
    { label: "Total Commission Based On Overall Clear ERC Fees", key: "totalCommissionBasedOnOverallClearErcFees" },
    {
        label: "Total Potential Future Still Owed Affiliate Commission",
        key: "totalPotentialFutureStillOwedAffiliateCommission",
    },
    { label: "Total Commission Percentage", key: "totalCommissionPercentage" },
    { label: "CPA On File", key: "cpaOnFile" },
    { label: "Total CPA Costs", key: "totalCpaCosts" },
    { label: "CPA Costs Paid", key: "cpaCostsPaid" },
    { label: "CPA Costs Still Owed", key: "cpaCostsStillOwed" },
    { label: "Total Tax Attorney Costs", key: "totalTaxAttorneyCosts" },
    { label: "Tax Attorney Costs Paid", key: "taxAttorneyCostsPaid" },
    { label: "Tax Attorney Costs Owed", key: "taxAttorneyCostsOwed" },
    { label: "Total Other Costs", key: "totalOtherCosts" },
    { label: "Other Costs Paid", key: "otherCostsPaid" },
    { label: "Other Costs Still Owed", key: "otherCostsStillOwed" },
    { label: "Total Commissions And Costs", key: "totalCommissionsAndCosts" },
    // { label: "Net Revenue With Q3", key: "netRevenueWithQ3" },
    // { label: "Net Revenue As A Percentage Of Fees With Q3", key: "netRevenueAsAPercentageOfFeesWithQ3" },
    // { label: "Net Revenue Without Q3", key: "netRevenueWithoutQ3" },
    // { label: "Net Revenue As A Percentage Of Fees Without Q3", key: "netRevenueAsAPercentageOfFeesWithoutQ3" },
    { label: "Is There A Contract", key: "isThereAContract" },
    { label: "Is There A Clawback Contract", key: "isThereAClawbackContract" },
    { label: "Was There A Request For Money Back", key: "wasThereARequestForMoneyBack" },
    { label: "Does The File Have A Substantiation Package", key: "doesTheFileHaveASubstantiationPackage" },
    { label: "Estimated Future Cost To Substantiate File", key: "estimatedFutureCostToSubstantiateFile" },
    {
        label: "Affiliate Refund Liability For Amount Already Paid",
        key: "affiliateRefundLiabilityForAmountAlreadyPaid",
    },
    { label: "CPA Future Maintenance And Appeal Costs", key: "cpaFutureMaintenanceAndAppealCosts" },
    { label: "Tax Attorney Projected Costs", key: "taxAttorneyProjectedCosts" },
    { label: "Estimated Flat Rate To Fix A File", key: "estimatedFlatRateToFixAFile" },
    { label: "Estimated Past In House Costs", key: "estimatedPastInHouseCosts" },
    {
        label: "Estimated Future In House Costs At 40k Per Month For 5 Years",
        key: "estimatedFutureInhouseCostsAt_40kPerMonthFor_5yrs",
    },
    { label: "Link To Folder", key: "linkToFolder" },
    { label: "Folder Href", key: "folderHref" },
    { label: "Tax Liability Q2 2020", key: "taxLiabilityQ2_2020" },
    { label: "Tax Liability Q3 2020", key: "taxLiabilityQ3_2020" },
    { label: "Tax Liability Q4 2020", key: "taxLiabilityQ4_2020" },
    { label: "Tax Liability Q1 2021", key: "taxLiabilityQ1_2021" },
    { label: "Tax Liability Q2 2021", key: "taxLiabilityQ2_2021" },
    { label: "Tax Liability Q3 2021", key: "taxLiabilityQ3_2021" },
    { label: "Tax Liability Q4 2021", key: "taxLiabilityQ4_2021" },
    { label: "Is The Deal a Bad Deal", key: "isBadDeal" },
    { label: "Is Scott Or Jason", key: "isScottOrJason" },
];
function ErcEntities() {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const queryClient = useQueryClient();
    const [showCompanyPicker, setShowCompanyPicker] = React.useState(false);
    const [showDealTagManager, setShowDealTagManager] = React.useState(false);
    const [showRefunds, setShowRefunds] = React.useState(false);
    const [showReceived, setShowReceived] = React.useState(false);
    const [showAmountFiled, setShowAmountFiled] = React.useState(false);
    const [showDidIrsIssueRefund, setShowDidIrsIssueRefund] = React.useState(false);
    const [ignoreFields, setIgnoreFields] = React.useState<string[]>([]);
    const [currentFieldName, setCurrentFieldName] = React.useState("");
    const [showEdit, setShowEdit] = React.useState(false);
    const [totalRefundBeforeInterest, setTotalRefundBeforeInterest] = React.useState(0);
    const [totalClearErcFees, setTotalClearErcFees] = React.useState(0);
    const [totalOutstanding, setTotalOutstanding] = React.useState(0);
    const [currentEntry, setCurrentEntry] = React.useState<ErcEntity | null>(null);
    const [dealPayTypes, setDealPayTypes] = React.useState<string[]>([]);
    const [showAffiliateInfo, setShowAffiliateInfo] = React.useState(false);
    const [showAmountOnFile, setShowAmountOnFile] = React.useState(false);
    const [showCpaTaxAttorneyDetails, setShowCpaTaxAttorneyDetails] = React.useState(false);
    const [showIssues, setShowIssues] = React.useState(false);
    const [showCosts, setShowCosts] = React.useState(false);
    const [showContracts, setShowContracts] = React.useState(false);
    const [showQ3Info, setShowQ3Info] = React.useState(false);
    const [newTagName, setNewTagName] = React.useState("");
    const [showReportModal, setShowReportModal] = React.useState(false);
    const [filters, setFilters] = React.useState<Filter[]>([]);
    const [reportType, setReportType] = React.useState<number | undefined>();
    const [entries, setEntries] = React.useState<any[] | null>([]);
    const [showTaxLiabilities, setShowTaxLiabilities] = React.useState(false);
    const [showBasicFields, setShowBasicFields] = React.useState(true);
    const [showAllFields, setShowAllFields] = React.useState(false);
    const [showReportDebug, setShowReportDebug] = React.useState(false);
    const [fieldChecks, setFieldChecks] = React.useState<any>({});
    const [showReportPDF, setShowReportPDF] = React.useState(false);
    const [showOwedMainAndSubAffiliateCommission, setShowOwedMainAndSubAffiliateCommission] = React.useState(false);
    const [showOwedMainAndSubAffiliateCommissionPayLater, setShowOwedMainAndSubAffiliateCommissionPayLater] =
        React.useState(false);
    const [showOtherCostsStillOwed, setShowOtherCostsStillOwed] = React.useState(false);
    const [showCalculatedPotentialIncome, setShowCalculatedPotentialIncome] = React.useState(false);

    useEffect(() => {
        const fields = [
            "address",
            "city",
            "state",
            "country",
            "phone",
            "email",
            "website",
            "defaultFolder",
            "name",
            "linkToFolder",
            "netRevenueWithQ3",
            "netRevenueAsAPercentageOfFeesWithQ3",
            "netRevenueWithoutQ3",
            "netRevenueAsAPercentageOfFeesWithoutQ3",
        ];
        if (showAllFields) {
            setIgnoreFields(fields);
            return;
        }
        if (!showRefunds) {
            fields.push(
                "amountOfRefundIssuedForQ2_2020",
                "amountOfRefundIssuedForQ3_2020",
                "amountOfRefundIssuedForQ4_2020",
                "amountOfRefundIssuedForQ1_2021",
                "amountOfRefundIssuedForQ2_2021",
                "amountOfRefundIssuedForQ3_2021",
                "amountOfRefundIssuedForQ4_2021",
            );
        }
        if (!showReceived) {
            fields.push(
                "didIrsRecieveQ2_2020",
                "didIrsRecieveQ3_2020",
                "didIrsRecieveQ4_2020",
                "didIrsRecieveQ1_2021",
                "didIrsRecieveQ2_2021",
                "didIrsRecieveQ3_2021",
                "didIrsRecieveQ4_2021",
            );
        }
        if (!showDidIrsIssueRefund) {
            fields.push(
                "didTheIrsIssueARefundForQ2_2020",
                "didTheIrsIssueARefundForQ3_2020",
                "didTheIrsIssueARefundForQ4_2020",
                "didTheIrsIssueARefundForQ1_2021",
                "didTheIrsIssueARefundForQ2_2021",
                "didTheIrsIssueARefundForQ3_2021",
                "didTheIrsIssueARefundForQ4_2021",
            );
        }
        if (!showAmountFiled) {
            fields.push(
                "q2_2020_941XAmountFiled",
                "q3_2020_941XAmountFiled",
                "q4_2020_941XAmountFiled",
                "q1_2021_941XAmountFiled",
                "q2_2021_941XAmountFiled",
                "q3_2021_941XAmountFiled",
                "q4_2021_941XAmountFiled",
            );
        }
        if (!showAmountOnFile) {
            fields.push(
                "amountOnFileWithTheIrsForQ2_2020",
                "amountOnFileWithTheIrsForQ3_2020",
                "amountOnFileWithTheIrsForQ4_2020",
                "amountOnFileWithTheIrsForQ1_2021",
                "amountOnFileWithTheIrsForQ2_2021",
                "amountOnFileWithTheIrsForQ3_2021",
                "amountOnFileWithTheIrsForQ4_2021",
            );
        }
        if (!showTaxLiabilities) {
            fields.push(
                "taxLiabilityQ2_2020",
                "taxLiabilityQ3_2020",
                "taxLiabilityQ4_2020",
                "taxLiabilityQ1_2021",
                "taxLiabilityQ2_2021",
                "taxLiabilityQ3_2021",
                "taxLiabilityQ4_2021",
            );
        }
        if (!showCalculatedPotentialIncome) {
            fields.push("Calculated Potential Income");
            fields.push("Removed For Bad Quarters");
            fields.push("Removed For Q3 2021");
            fields.push("Removed For Outstanding Fees");
            fields.push("Removed For Owed Commissions");
        }
        if (!showOtherCostsStillOwed) {
            fields.push("taxAttorneyCostsOwed", "cpaCostsStillOwed", "otherCostsStillOwed");
        }
        if (!showCosts) {
            fields.push(
                "taxAdvocacyFee",
                "taxAdvocacyFeePaid",
                "amountStillOwed",
                "amountStillOwedWithoutQ3",
                "q3MoneyThatWeMayHaveToRefund",
                "totalRefundsIssued",
                "isThereATaxLiability",
                "howMuchOfErcCreditTransferredDueToTaxLiability",
                "taxAttorneyProjectedCosts",
                "taxAttorneyProjectedCostsPaid",
                "taxAttorneyProjectedCostsOwed",
                "totalCpaCosts",
                "cpaCostsPaid",
                // "cpaCostsStillOwed",
                "totalTaxAttorneyCosts",
                "taxAttorneyCostsPaid",
                // "taxAttorneyCostsOwed",
                "totalOtherCosts",
                "otherCostsPaid",
                // "otherCostsStillOwed",
                "totalCommissionsAndCosts",
                "estimatedFutureCostToSubstantiateFile",
                "estimatedPastInHouseCosts",
                "estimatedFlatRateToFixAFile",
                "estimatedFutureInhouseCostsAt_40kPerMonthFor_5yrs",
            );
        }
        if (!showIssues) {
            fields.push(
                "anyIssuesWithQ2_2020IrsTranscripts",
                "anyIssuesWithQ3_2020IrsTranscripts",
                "anyIssuesWithQ4_2020IrsTranscripts",
                "anyIssuesWithQ1_2021IrsTranscripts",
                "anyIssuesWithQ2_2021IrsTranscripts",
                "anyIssuesWithQ3_2021IrsTranscripts",
                "anyIssuesWithQ4_2021IrsTranscripts",
                "issuesWithQ2_2020IrsTranscripts",
                "issuesWithQ3_2020IrsTranscripts",
                "issuesWithQ4_2020IrsTranscripts",
                "issuesWithQ1_2021IrsTranscripts",
                "issuesWithQ2_2021IrsTranscripts",
                "issuesWithQ3_2021IrsTranscripts",
                "issuesWithQ4_2021IrsTranscripts",
            );
        }
        if (!showCpaTaxAttorneyDetails) {
            fields.push(
                "cpaOnFile",
                "totalCpaCosts",
                "cpaCostsPaid",
                // "cpaCostsStillOwed",
                "totalTaxAttorneyCosts",
                "taxAttorneyCostsPaid",
                // "taxAttorneyCostsOwed",
                "cpaFutureMaintenanceAndAppealCosts",
            );
        }
        if (!showContracts) {
            fields.push("isThereAContract", "isThereAClawbackContract");
        }
        if (!showQ3Info) {
            fields.push(
                "didWeFileQ3_941X",
                // "q3_2021_941XAmountFiled",
                "didIrsRecieveQ3_2021",
                // "anyIssuesWithQ3_2021IrsTranscripts",
                "amountOnFileWithTheIrsForQ3_2021",
                "didTheIrsIssueARefundForQ3_2021",
                "amountOfRefundIssuedForQ3_2021",
                "wasThereAQ3Disallowance",
                "amountStillOwedWithoutQ3",
                "q3MoneyThatWeMayHaveToRefund",
                "netRevenueWithQ3",
                "netRevenueAsAPercentageOfFeesWithQ3",
                "netRevenueWithoutQ3",
                "netRevenueAsAPercentageOfFeesWithoutQ3",
            );
        }
        if (!showOwedMainAndSubAffiliateCommission) {
            fields.push(
                "currentStillOwedMainAffiliateCommission",
                "currentStillOwedSubAffiliateCommission",
                "currentStillOwedJacobsCommission",
                "currentStillOwedMikesCommission",
                "currentStillOwedTomsCommission",
            );
        }
        if (!showOwedMainAndSubAffiliateCommissionPayLater) {
            fields.push(
                "totalMikesCommissionBasedOnOverallClearErcFees",
                "totalJacobsCommissionBasedOnOverallClearErcFees",
                "totalTomsCommissionBasedOnOverallClearErcFees",
                "totalMainAffiliateCommissionBasedOnOverallClearErcFees",
                "totalSubAffiliateCommissionBasedOnOverallClearErcFees",
            );
        }
        if (!showAffiliateInfo) {
            fields.push(
                "mainAffiliateName",
                "mainAffiliatePercentage",
                "mainAffiliateCommissionsBasedOnAmountRefunded",
                "totalMainAffiliateCommissionDueBasedOnFeesAlreadyPaid",
                "currentPaidMainAffiliateCommission",

                "potentialFutureStillOwedMainAffiliateCommission",
                "subAffiliateName",
                "subAffiliatePercentage",
                "subAffiliateCommissionBasedOnAmountRefunded",
                "totalSubAffiliateCommissionDueBasedOnFeesAlreadyPaid",
                "currentPaidSubAffiliateCommission",

                "potentialFutureStillOwedSubAffiliateCommission",
                "mikesRelatedCommissionPercentage",
                "amountDueToMikeBasedOnAmountRefunded",
                "totalMikesCommissionDueBasedOnFeesAlreadyPaid",
                "currentPaidMikesCommission",

                "potentialFutureStillOwedMikesAffiliateCommission",
                "jacobsRelatedCommissionPercentage",
                "amountDueToJacobBasedOnAmountRefunded",
                "totalJacobsCommissionDueBasedOnFeesAlreadyPaid",
                "currentPaidJacobsCommission",

                "potentialFutureStillOwedJacobsAffiliateCommission",
                "tomsRelatedCommissionPercentage",
                "amountDueToTomBasedOnAmountRefunded",
                "totalTomsCommissionDueBasedOnFeesAlreadyPaid",
                "currentPaidTomsCommission",

                "potentialFutureStillOwedTomsAffiliateCommission",
                "affiliateOnAccount",
                "affliateGroupTeam",
                "affiliatteRefundLiabilityForAmountAlreadyPaid",
            );
            if (!showBasicFields) {
                fields.push(
                    "isAPeo",
                    "dealPayType",
                    "feePercentage",
                    "dealPayStatus",
                    "wasTheDealFinanced",
                    "didTheIrsIssueARefundFullPartialOrNone",
                    // "clientRefundAmountBasedOn_941XsBeforeInterest",
                    // "clearErcFees",
                    // "amountPaid",
                    "isThereMonitoring",
                    "whatTypeOfMonitoring",
                    "totalCommissionDue",
                    "totalPaidCommission",
                    "totalCurrentStillOwedCommission",
                    "totalCommissionBasedOnOverallClearErcFees",
                    "totalPotentialFutureStillOwedAffiliateCommission",
                    "totalCommissionPercentage",
                    "wasThereARequestForMoneyBack",
                    "doesTheFileHaveASubstantiationPackage",
                    "affiliateRefundLiabilityForAmountAlreadyPaid",
                    "folderHref",
                );
            }
        }
        setIgnoreFields(fields);
    }, [
        showRefunds,
        showReceived,
        showAmountFiled,
        showDidIrsIssueRefund,
        showAffiliateInfo,
        showAmountOnFile,
        showIssues,
        showCpaTaxAttorneyDetails,
        showCosts,
        showContracts,
        showQ3Info,
        showTaxLiabilities,
        showBasicFields,
        showAllFields,
        showOwedMainAndSubAffiliateCommission,
        showOtherCostsStillOwed,
        showCalculatedPotentialIncome,
        showOwedMainAndSubAffiliateCommissionPayLater,
    ]);
    function resetFilters() {
        setShowRefunds(false);
        setShowReceived(false);
        setShowAmountFiled(false);
        setShowDidIrsIssueRefund(false);
        setShowAffiliateInfo(false);
        setShowAmountOnFile(false);
        setShowIssues(false);
        setShowCpaTaxAttorneyDetails(false);
        setShowCosts(false);
        setShowContracts(false);
        setShowQ3Info(false);
        setShowTaxLiabilities(false);
        setShowBasicFields(false);
        setShowAllFields(false);
        setShowOwedMainAndSubAffiliateCommission(false);
        setShowOwedMainAndSubAffiliateCommissionPayLater(false);
        setShowOtherCostsStillOwed(false);
        setShowCalculatedPotentialIncome(false);
    }
    function getTextFromDealTagId(dealTagId: number) {
        const found = dealTagOptionsQuery.data?.find((d) => d.id === dealTagId);
        return found?.name.toLowerCase().trim();
    }
    function getDealTagsForDealAndField(dealId: number, fieldName: string) {
        // console.log("dealId", dealId);
        // console.log("fieldName", fieldName);
        const dealTagIdsForDeal = dealTagOptionsQuery.data?.map((d) => {
            if (d.fieldName === fieldName) {
                return d.id;
            }
        });
        const foundTags = dealTagsQuery.data?.filter(
            (e) => e.dealId === dealId && dealTagIdsForDeal?.includes(e.tagId),
        );
        return foundTags;
    }
    function getDealTagTextForDealAndField(dealId: number, fieldName: string) {
        const foundTags = getDealTagsForDealAndField(dealId, fieldName);
        return foundTags?.map((d) => getTextFromDealTagId(d.tagId));
    }

    const companiesQuery = useQuery(["companies"], async () => {
        const response = await companyService.getAll();
        if (response) {
            return response.data;
        }
    });
    const ercEntitiesQuery = useQuery(["erc-entities"], async () => {
        const response = await ercEntityService.getAll();
        if (response) {
            return response.data;
        }
    });
    const userCompanyQuery = useQuery(["user-company"], async () => {
        const response = await userCompanyService.getAll();
        if (response) {
            return response.data;
        }
    });
    const ercUserQuery = useQuery(["erc-users"], async () => {
        const response = await userService.getAll();
        if (response) {
            return response.data;
        }
    });
    const dealTagOptionsQuery = useQuery(["deal-tag-options"], async () => {
        const response = await dealTagOptionsService.getAll();
        if (response) {
            return response.data;
        }
    });
    const dealTagsQuery = useQuery(["deal-tags"], async () => {
        const response = await dealTagService.getAll();
        if (response) {
            return response.data;
        }
    });
    const dealsQuery = useQuery(
        ["deals"],
        async () => {
            const response = await dealsService.getAll();
            if (response) {
                const finalResponse = response.data.map((d) => {
                    const highRiskIssues: string[] = [];
                    let calculatedPotentialIncome = 0;
                    // Go through deal tags and find the high risk issues
                    let dealTagIdsForDeal = dealTagOptionsQuery.data?.map((d) => {
                        if (["Yes"].includes(d.name) && d.fieldName === "isAPeo") {
                            return d.id;
                        }
                    });
                    let foundTags = dealTagsQuery.data?.filter(
                        (e) => e.dealId === d.id && dealTagIdsForDeal?.includes(e.tagId),
                    );
                    if (foundTags?.length ?? 0 > 0) {
                        highRiskIssues.push("Is a PEO");
                    }
                    dealTagIdsForDeal = dealTagOptionsQuery.data?.map((d) => {
                        if (["Yes"].includes(d.name) && d.fieldName === "isThereAClawbackContract") {
                            return d.id;
                        }
                    });
                    foundTags = dealTagsQuery.data?.filter(
                        (e) => e.dealId === d.id && dealTagIdsForDeal?.includes(e.tagId),
                    );
                    if (foundTags?.length ?? 0 > 0) {
                        highRiskIssues.push("Clawback contract");
                    }
                    const ourFees = Number(d.clearErcFees.replace(/[^0-9.-]+/g, ""));
                    const ourPercentage = Number(d.feePercentage.replace(/[^0-9.-]+/g, "")) / 100;
                    calculatedPotentialIncome = ourFees;
                    let removedForBadQuarters = 0;
                    let removedForQ32021 = 0;
                    let removedForOutstandingFees = 0;
                    const q2_2020 = Number(d.q2_2020_941XAmountFiled.replace(/[^0-9.-]+/g, ""));
                    const q3_2020 = Number(d.q3_2020_941XAmountFiled.replace(/[^0-9.-]+/g, ""));
                    const q4_2020 = Number(d.q4_2020_941XAmountFiled.replace(/[^0-9.-]+/g, ""));
                    const q1_2021 = Number(d.q1_2021_941XAmountFiled.replace(/[^0-9.-]+/g, ""));
                    const q2_2021 = Number(d.q2_2021_941XAmountFiled.replace(/[^0-9.-]+/g, ""));
                    const q3_2021 = Number(d.q3_2021_941XAmountFiled.replace(/[^0-9.-]+/g, ""));
                    const q4_2021 = Number(d.q4_2021_941XAmountFiled.replace(/[^0-9.-]+/g, ""));

                    const cpaOutstanding = Number(d.cpaCostsStillOwed.replace(/[^0-9.-]+/g, ""));
                    const taxAttorneyOutstanding = Number(d.taxAttorneyCostsOwed.replace(/[^0-9.-]+/g, ""));
                    const otherOutstanding = Number(d.otherCostsStillOwed.replace(/[^0-9.-]+/g, ""));
                    // const owedCommissions = Number(
                    //     d.totalCommissionBasedOnOverallClearErcFees.replace(/[^0-9.-]+/g, ""),
                    // );

                    // remove any q3_2021 fees
                    calculatedPotentialIncome -= q3_2021 * ourPercentage;
                    removedForQ32021 += q3_2021 * ourPercentage;
                    removedForOutstandingFees += cpaOutstanding + taxAttorneyOutstanding + otherOutstanding;

                    const issuesFieldNames = [
                        { field: "anyIssuesWithQ2_2020IrsTranscripts", value: q2_2020 },
                        { field: "anyIssuesWithQ3_2020IrsTranscripts", value: q3_2020 },
                        { field: "anyIssuesWithQ4_2020IrsTranscripts", value: q4_2020 },
                        { field: "anyIssuesWithQ1_2021IrsTranscripts", value: q1_2021 },
                        { field: "anyIssuesWithQ2_2021IrsTranscripts", value: q2_2021 },
                        // { field: "anyIssuesWithQ3_2021IrsTranscripts", value: q3_2021 },
                        { field: "anyIssuesWithQ4_2021IrsTranscripts", value: q4_2021 },
                        { field: "issuesWithQ3_2020IrsTranscripts", value: q3_2020 },
                    ];

                    // Go through the issues and remove the fees
                    for (const [key, value] of Object.entries(issuesFieldNames)) {
                        const amount = value.value;
                        const fieldName = value.field;
                        // console.log({ field: value.field, value: value.value });
                        const dealTags = dealTagOptionsQuery.data?.map((d) => {
                            if (d.fieldName === fieldName) {
                                if (
                                    !["no", "peo", "did not file", "waiting on transcripts"].includes(
                                        d.name.toLowerCase().trim(),
                                    )
                                ) {
                                    return d.id;
                                }
                            }
                        });
                        const foundDealTags = dealTagsQuery.data?.filter(
                            (e) => e.dealId === d.id && dealTags?.includes(e.tagId),
                        );
                        // console.log({ fieldName, amount, foundDealTags });
                        if (foundDealTags?.length ?? 0 > 0) {
                            calculatedPotentialIncome -= amount * ourPercentage;
                            removedForBadQuarters += amount * ourPercentage;
                        }
                        // }
                    }

                    const totalCommissionPercentage =
                        Number(d.totalCommissionPercentage.replace(/[^0-9.-]+/g, "")) / 100;

                    const owedCommissions = calculatedPotentialIncome * totalCommissionPercentage;

                    // Remove outstanding fees
                    calculatedPotentialIncome -= cpaOutstanding + taxAttorneyOutstanding + otherOutstanding;

                    calculatedPotentialIncome -= owedCommissions;

                    // Make a field for Scott or Jason and tag deals appropriately
                    let isScottOrJason = "";
                    const jasonAffiliateGroupTeamTagId = 63;
                    const jasonSubAffiliate = 160;
                    const jasonMainAffiliate = 97;
                    const jasonAffiliateOnAccount = 19;

                    const scottAffiliateGroupTeamTagId = 70;
                    const scottSubAffiliate = 161;
                    const scottMainAffiliate = 98;
                    const scottAffiliateOnAccount = 20;

                    // Go through the fields and check if they are tagged as Scott or Jason
                    let tags = getDealTagsForDealAndField(d.id ?? -1, "affliateGroupTeam");
                    // console.log({ tags, id: d.id, field: "affliateGroupTeam" });
                    if ((tags?.length ?? 0 > 0) && tags !== undefined) {
                        for (const tag of tags) {
                            if (tag.tagId === jasonAffiliateGroupTeamTagId) {
                                isScottOrJason = "Jason";
                            } else if (tag.tagId === scottAffiliateGroupTeamTagId) {
                                isScottOrJason = "Scott";
                            }
                        }
                    } else {
                        tags = getDealTagsForDealAndField(d.id ?? -1, "mainAffiliateName");
                        if ((tags?.length ?? 0 > 0) && tags !== undefined) {
                            for (const tag of tags) {
                                if (tag.tagId === jasonMainAffiliate) {
                                    isScottOrJason = "Jason";
                                } else if (tag.tagId === scottMainAffiliate) {
                                    isScottOrJason = "Scott";
                                }
                            }
                        } else {
                            tags = getDealTagsForDealAndField(d.id ?? -1, "subAffiliateName");
                            if ((tags?.length ?? 0 > 0) && tags !== undefined) {
                                for (const tag of tags) {
                                    if (tag.tagId === jasonSubAffiliate) {
                                        isScottOrJason = "Jason";
                                    } else if (tag.tagId === scottSubAffiliate) {
                                        isScottOrJason = "Scott";
                                    }
                                }
                            } else {
                                tags = getDealTagsForDealAndField(d.id ?? -1, "affiliateOnAccount");
                                if ((tags?.length ?? 0 > 0) && tags !== undefined) {
                                    for (const tag of tags) {
                                        if (tag.tagId === jasonAffiliateOnAccount) {
                                            isScottOrJason = "Jason";
                                        } else if (tag.tagId === scottAffiliateOnAccount) {
                                            isScottOrJason = "Scott";
                                        }
                                    }
                                }
                            }
                        }
                    }

                    return {
                        id: d.id,
                        ...d,
                        highRiskIssues: highRiskIssues.join(", "),
                        "Calculated Potential Income": USDollar.format(calculatedPotentialIncome),
                        "Removed For Bad Quarters": USDollar.format(removedForBadQuarters),
                        "Removed For Q3 2021": USDollar.format(removedForQ32021),
                        "Removed For Outstanding Fees": USDollar.format(removedForOutstandingFees),
                        "Removed For Owed Commissions": USDollar.format(owedCommissions),
                        isScottOrJason,
                    };
                });
                return finalResponse;
            }
        },
        { enabled: dealTagsQuery.isSuccess && dealTagOptionsQuery.isSuccess },
    );
    useEffect(() => {
        let totalRefundBeforeInterestCount = 0;
        let totalClearErcFeesCount = 0;
        const payTypes: string[] = [];
        dealsQuery.data?.map((d) => {
            totalRefundBeforeInterestCount += Number(
                d.clientRefundAmountBasedOn_941XsBeforeInterest.replace(/[^0-9.-]+/g, ""),
            );
            totalClearErcFeesCount += Number(d.clearErcFees.replace(/[^0-9.-]+/g, ""));
            if (!payTypes.includes(d.dealPayType.trim())) {
                payTypes.push(d.dealPayType.trim());
            }
            // console.log(
            //     d.companyName,
            //     USDollar.format(Number(d.clientRefundAmountBasedOn_941XsBeforeInterest.replace(/[^0-9.-]+/g, ""))),
            // );
        });

        setTotalRefundBeforeInterest(totalRefundBeforeInterestCount);
        // setTotalClearErcFees(totalClearErcFeesCount);
        setDealPayTypes(payTypes);
        // alert(USDollar.format(totalRefundBeforeInterestCount));
    }, [dealsQuery.data]);
    useEffect(() => {
        let totalClearErcFeesCount = 0;
        let totalOutstandingCount = 0;
        const results = (dealsQuery.data ?? []).map((e) => {
            if (reportType === ReportTypes.BasicLiabilityReporting) {
                // If the deal has a dealtag of "partial upfront" or "paid in full" then show it
                const dealTagIdsForDeal = dealTagOptionsQuery.data?.map((d) => {
                    if (
                        ["Already Paid In Full", "Partial $ Paid"].includes(d.name) &&
                        d.fieldName === "dealPayStatus"
                    ) {
                        return d.id;
                    }
                });
                const foundTags = dealTagsQuery.data?.filter(
                    (d) => d.dealId === e.id && dealTagIdsForDeal?.includes(d.tagId),
                );
                console.log({ foundTags });
                if (foundTags?.length ?? 0 > 0) {
                    totalClearErcFeesCount += Number(e.clearErcFees.replace(/[^0-9.-]+/g, ""));
                    totalOutstandingCount += Number(e.amountStillOwed.replace(/[^0-9.-]+/g, ""));
                    return {
                        ...e,
                    };
                }
                setShowIssues(true);
                setShowAmountFiled(true);
                setShowBasicFields(true);
                setShowOwedMainAndSubAffiliateCommission(true);
                setShowOtherCostsStillOwed(true);
                setFieldChecks({
                    dealPayStatus: ["Already Paid In Full", "Partial $ Paid"],
                });
                return;
            } else if (reportType === ReportTypes.MoneyStillOwedOnDealsWeHaveBeenPaidOn) {
                // If the deal has a dealtag of "partial upfront" or "paid in full" then show it
                const dealTagIdsForDeal = dealTagOptionsQuery.data?.map((d) => {
                    if (["Partial $ Paid"].includes(d.name) && d.fieldName === "dealPayStatus") {
                        return d.id;
                    }
                });
                const foundTags = dealTagsQuery.data?.filter(
                    (d) => d.dealId === e.id && dealTagIdsForDeal?.includes(d.tagId),
                );
                console.log({ foundTags });
                if (foundTags?.length ?? 0 > 0) {
                    totalClearErcFeesCount += Number(e.clearErcFees.replace(/[^0-9.-]+/g, ""));
                    totalOutstandingCount += Number(e.amountStillOwed.replace(/[^0-9.-]+/g, ""));
                    return {
                        ...e,
                    };
                }
                setShowIssues(true);
                setShowAmountFiled(true);
                setShowBasicFields(true);
                setShowOwedMainAndSubAffiliateCommission(true);
                setShowOtherCostsStillOwed(true);
                setFieldChecks({
                    dealPayStatus: ["Already Paid In Full", "Partial $ Paid"],
                });
                return;
            } else if (reportType === ReportTypes.PayLaterDeals) {
                const dealTagIdsForDeal = dealTagOptionsQuery.data?.map((d) => {
                    if (d.fieldName === "isBadDeal") {
                        return d.id;
                    }
                    if (d.fieldName === "isAPeo" && d.name === "Yes") {
                        return d.id;
                    }
                });
                const foundTags = dealTagsQuery.data?.filter(
                    (d) => d.dealId === e.id && dealTagIdsForDeal?.includes(d.tagId),
                );

                if (Number(e.amountPaid.replace(/[^0-9.-]+/g, "")) === 0 && (foundTags?.length ?? 0) === 0) {
                    totalClearErcFeesCount += Number(e.clearErcFees.replace(/[^0-9.-]+/g, ""));
                    totalOutstandingCount += Number(e.amountStillOwed.replace(/[^0-9.-]+/g, ""));
                    return {
                        ...e,
                    };
                }
                setShowIssues(true);
                setShowAmountFiled(true);
                setShowBasicFields(true);
                setShowOwedMainAndSubAffiliateCommissionPayLater(true);
                setShowOtherCostsStillOwed(true);

                return;
            } else if (reportType === ReportTypes.BadDeals) {
                const dealTagIdsForDeal = dealTagOptionsQuery.data?.map((d) => {
                    if (d.fieldName === "isBadDeal") {
                        return d.id;
                    }
                });
                const foundTags = dealTagsQuery.data?.filter(
                    (d) => d.dealId === e.id && dealTagIdsForDeal?.includes(d.tagId),
                );

                if ((foundTags?.length ?? 0) > 0) {
                    totalClearErcFeesCount += Number(e.clearErcFees.replace(/[^0-9.-]+/g, ""));
                    totalOutstandingCount += Number(e.amountStillOwed.replace(/[^0-9.-]+/g, ""));
                    return {
                        ...e,
                    };
                }
                setShowIssues(true);
                setShowAmountFiled(true);
                setShowBasicFields(true);
                setShowOwedMainAndSubAffiliateCommissionPayLater(true);
                setShowOtherCostsStillOwed(true);

                return;
            } else if (reportType === ReportTypes.PEO) {
                const dealTagIdsForDeal = dealTagOptionsQuery.data?.map((d) => {
                    if (d.fieldName === "isAPeo" && d.name === "Yes") {
                        return d.id;
                    }
                });
                const foundTags = dealTagsQuery.data?.filter(
                    (d) => d.dealId === e.id && dealTagIdsForDeal?.includes(d.tagId),
                );

                if ((foundTags?.length ?? 0) > 0) {
                    totalClearErcFeesCount += Number(e.clearErcFees.replace(/[^0-9.-]+/g, ""));
                    totalOutstandingCount += Number(e.amountStillOwed.replace(/[^0-9.-]+/g, ""));
                    return {
                        ...e,
                    };
                }
                setShowIssues(true);
                setShowAmountFiled(true);
                setShowBasicFields(true);
                setShowOwedMainAndSubAffiliateCommissionPayLater(true);
                setShowOtherCostsStillOwed(true);

                return;
            } else if (reportType === ReportTypes.OtherLiabilities) {
                // If the deal has a dealtag of "partial upfront" or "paid in full" then show it
                const dealTagIdsForDeal = dealTagOptionsQuery.data?.map((d) => {
                    if (
                        ["Already Paid In Full", "Partial $ Paid"].includes(d.name) &&
                        d.fieldName === "dealPayStatus"
                    ) {
                        return d.id;
                    }
                });
                const foundTags = dealTagsQuery.data?.filter(
                    (d) => d.dealId === e.id && dealTagIdsForDeal?.includes(d.tagId),
                );
                console.log({ foundTags });
                if (foundTags?.length ?? 0 > 0) {
                    totalClearErcFeesCount += Number(e.clearErcFees.replace(/[^0-9.-]+/g, ""));
                    totalOutstandingCount += Number(e.amountStillOwed.replace(/[^0-9.-]+/g, ""));
                    return {
                        ...e,
                    };
                }
                setShowBasicFields(true);
                return;
            } else if (reportType === ReportTypes.FullLiabilityReport) {
                // If the deal has a dealtag of "partial upfront" or "paid in full" then show it
                const dealTagIdsForDeal = dealTagOptionsQuery.data?.map((d) => {
                    if (
                        ["Already Paid In Full", "Partial $ Paid"].includes(d.name) &&
                        d.fieldName === "dealPayStatus"
                    ) {
                        return d.id;
                    }
                });
                const foundTags = dealTagsQuery.data?.filter(
                    (d) => d.dealId === e.id && dealTagIdsForDeal?.includes(d.tagId),
                );
                console.log({ foundTags });
                if (foundTags?.length ?? 0 > 0) {
                    totalClearErcFeesCount += Number(e.clearErcFees.replace(/[^0-9.-]+/g, ""));
                    totalOutstandingCount += Number(e.amountStillOwed.replace(/[^0-9.-]+/g, ""));
                    return {
                        ...e,
                    };
                }
                return;
            } else {
                totalClearErcFeesCount += Number(e.clearErcFees.replace(/[^0-9.-]+/g, ""));
                totalOutstandingCount += Number(e.amountStillOwed.replace(/[^0-9.-]+/g, ""));
                return {
                    ...e,
                    // affiliates: affiliateNames,
                };
            }
        });

        // alert(totalClearErcFeesCount);
        setTotalClearErcFees(totalClearErcFeesCount);
        setTotalOutstanding(totalOutstandingCount);
        console.log({ totalClearErcFeesCount });
        setEntries(results.filter((r) => r));
    }, [dealTagOptionsQuery.data, dealTagsQuery.data, dealsQuery.data, reportType]);
    if (
        companiesQuery.isLoading ||
        ercEntitiesQuery.isLoading ||
        userCompanyQuery.isLoading ||
        ercUserQuery.isLoading ||
        dealsQuery.isLoading
    ) {
        return <div>Loading...</div>;
    }
    function numericEditor(item: { id: any }, item2: string[]) {
        if (item2[1] === null || item2[1] === "" || item2[1] === undefined) {
            item2[1] = "0";
        }
        return (
            <ButtonNeoGen
                size="sm"
                onClick={() => {
                    console.log({ id: item.id, field: item2[0], value: item2[1] });
                    SwalNeogenFire({
                        title: "Update Deal",
                        input: "text",
                        inputValue: item2[1],
                        showCancelButton: true,
                        confirmButtonText: "Update",
                        cancelButtonText: "Cancel",
                        // preConfirm: async (value: any) => {
                        //     await dealsService.update(item.id, {
                        //         [item2[0]]: value,
                        //     });
                        //     await queryClient.invalidateQueries(["deals"]);
                        // },
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            console.log(result);
                            const value = result.value;
                            await dealsService.update(item.id, {
                                [item2[0]]: value,
                            });
                            await queryClient.invalidateQueries(["deals"]);
                        }
                    });
                }}
            >
                {/* <PrintPre>{item}</PrintPre> */}

                {USDollar.format(Number(item2[1].replace(/[^0-9.-]+/g, "")))}
            </ButtonNeoGen>
        );
    }
    function percentageEditor(item: { id: any }, item2: string[]) {
        if (item2[1] === null || item2[1] === "" || item2[1] === undefined) {
            item2[1] = "0";
        }
        return (
            <ButtonNeoGen
                size="sm"
                onClick={() => {
                    console.log({ id: item.id, field: item2[0], value: item2[1] });
                    SwalNeogenFire({
                        title: "Update Deal",
                        input: "text",
                        inputValue: item2[1],
                        showCancelButton: true,
                        confirmButtonText: "Update",
                        cancelButtonText: "Cancel",
                        // preConfirm: async (value: any) => {
                        //     await dealsService.update(item.id, {
                        //         [item2[0]]: value,
                        //     });
                        //     await queryClient.invalidateQueries(["deals"]);
                        // },
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            console.log(result);
                            const value = result.value;
                            await dealsService.update(item.id, {
                                [item2[0]]: value,
                            });
                            await queryClient.invalidateQueries(["deals"]);
                        }
                    });
                }}
            >
                {/* <PrintPre>{item}</PrintPre> */}
                {(Number(item2[1].replace(/[^0-9.-]+/g, "")) / 100).toLocaleString(undefined, {
                    style: "percent",
                    minimumFractionDigits: 2,
                })}
            </ButtonNeoGen>
        );
    }
    function getReportTypeName(reportType: number) {
        switch (reportType) {
            case ReportTypes.FullLiabilityReport:
                return "Full liability report";
            case 2:
                return "Client Money back report";
            case 3:
                return "This is also based on money received full or partial pay";
            case 4:
                return "Revenue Collected Q3";
            case 5:
                return "Potential liability report for incorrect calculations";
            case 6:
                return "Potential liabilities for Clients paid that with quarters not received";
            case ReportTypes.BasicLiabilityReporting:
                return "Liability on Paid Deals";
            case ReportTypes.OtherLiabilities:
                return "Other liabilities";
            case ReportTypes.PayLaterDeals:
                return "Liability on Pay Later Deals";
            case ReportTypes.BadDeals:
                return "Bad Deals";
            case ReportTypes.PEO:
                return "PEO";
            case ReportTypes.MoneyStillOwedOnDealsWeHaveBeenPaidOn:
                return "Money owed on part paid deals";
            default:
                return "All";
        }
    }

    const formatters: Formatter[] = [
        { field: "highRiskIssues", type: "Tag" },
        { field: "wasTheDealFinanced", type: "DealTag" },
        { field: "isAPeo", type: "DealTag" },
        { field: "wasThereARequestForMoneyBack", type: "DealTag" },
        { field: "didWeFileQ3_941X", type: "DealTag" },
        // { field: "wasThereAQ3Disallowance", type: "Boolean" },
        { field: "dealPayType", type: "DealTag" },
        { field: "dealPayStatus", type: "DealTag" },
        { field: "didTheIrsIssueARefundFullPartialOrNone", type: "DealTag" },
        { field: "wasThereAQ3Disallowance", type: "DealTag" },
        { field: "isThereMonitoring", type: "DealTag" },
        { field: "whatTypeOfMonitoring", type: "DealTag" },
        { field: "affliateGroupTeam", type: "DealTag" },
        { field: "affiliateOnAccount", type: "DealTag" },
        { field: "cpaOnFile", type: "DealTag" },
        { field: "doesTheFileHaveASubstantiationPackage", type: "DealTag" },
        { field: "isThereAContract", type: "DealTag" },
        { field: "isThereAClawbackContract", type: "DealTag" },
        { field: "mainAffiliateName", type: "DealTag" },
        { field: "subAffiliateName", type: "DealTag" },
        { field: "didIrsRecieveQ2_2020", type: "DealTag" },
        { field: "didIrsRecieveQ3_2020", type: "DealTag" },
        { field: "didIrsRecieveQ4_2020", type: "DealTag" },
        { field: "didIrsRecieveQ1_2021", type: "DealTag" },
        { field: "didIrsRecieveQ2_2021", type: "DealTag" },
        { field: "didIrsRecieveQ3_2021", type: "DealTag" },
        { field: "didIrsRecieveQ4_2021", type: "DealTag" },
        { field: "isBadDeal", type: "DealTag" },
        { field: "didTheIrsIssueARefundForQ2_2020", type: "DealTag" },
        { field: "didTheIrsIssueARefundForQ3_2020", type: "DealTag" },
        { field: "didTheIrsIssueARefundForQ4_2020", type: "DealTag" },
        { field: "didTheIrsIssueARefundForQ1_2021", type: "DealTag" },
        { field: "didTheIrsIssueARefundForQ2_2021", type: "DealTag" },
        { field: "didTheIrsIssueARefundForQ3_2021", type: "DealTag" },
        { field: "didTheIrsIssueARefundForQ4_2021", type: "DealTag" },
        { field: "anyIssuesWithQ2_2020IrsTranscripts", type: "DealTag" },
        { field: "issuesWithQ3_2020IrsTranscripts", type: "DealTag" },
        { field: "anyIssuesWithQ4_2020IrsTranscripts", type: "DealTag" },
        { field: "anyIssuesWithQ1_2021IrsTranscripts", type: "DealTag" },
        { field: "anyIssuesWithQ2_2021IrsTranscripts", type: "DealTag" },
        { field: "anyIssuesWithQ3_2021IrsTranscripts", type: "DealTag" },
        { field: "anyIssuesWithQ4_2021IrsTranscripts", type: "DealTag" },
        { field: "folderHref", type: "Link" },
        {
            field: "clientRefundAmountBasedOn_941XsBeforeInterest",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        { field: "clearErcFees", type: "CustomFunctionFullRow", customFunction: numericEditor },
        { field: "amountPaid", type: "CustomFunctionFullRow", customFunction: numericEditor },
        { field: "cpaCostsStillOwed", type: "CustomFunctionFullRow", customFunction: numericEditor },
        { field: "otherCostsStillOwed", type: "CustomFunctionFullRow", customFunction: numericEditor },
        { field: "taxAttorneyCostsOwed", type: "CustomFunctionFullRow", customFunction: numericEditor },
        { field: "totalCommissionDue", type: "CustomFunctionFullRow", customFunction: numericEditor },
        { field: "totalPaidCommission", type: "CustomFunctionFullRow", customFunction: numericEditor },
        {
            field: "totalCurrentStillOwedCommission",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "totalCommissionBasedOnOverallClearErcFees",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "totalPotentialFutureStillOwedAffiliateCommission",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "amountOfRefundIssuedForQ2_2020",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "amountOfRefundIssuedForQ3_2020",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "amountOfRefundIssuedForQ4_2020",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "amountOfRefundIssuedForQ1_2021",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "amountOfRefundIssuedForQ2_2021",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "amountOfRefundIssuedForQ3_2021",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "amountOfRefundIssuedForQ4_2021",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "q2_2020_941XAmountFiled",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "q3_2020_941XAmountFiled",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "q4_2020_941XAmountFiled",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "q1_2021_941XAmountFiled",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "q2_2021_941XAmountFiled",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "q3_2021_941XAmountFiled",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "q4_2021_941XAmountFiled",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "mainAffiliateCommissionsBasedOnAmountRefunded",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "totalMainAffiliateCommissionDueBasedOnFeesAlreadyPaid",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "currentPaidMainAffiliateCommission",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "currentStillOwedainAffiliateCommission",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "totalMainAffiliateCommissionBasedOnOverallClearErcFees",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "potentialFutureStillOwedMainAffiliateCommission",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "totalSubAffiliateCommissionDueBasedOnFeesAlreadyPaid",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "currentPaidSubAffiliateCommission",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "currentStillOwedSubAffiliateCommission",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "totalSubAffiliateCommissionBasedOnOverallClearErcFees",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "potentialFutureStillOwedSubAffiliateCommission",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "subAffiliateCommissionBasedOnAmountRefunded",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "amountDueToMikeBasedOnAmountRefunded",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "totalMikesCommissionDueBasedOnFeesAlreadyPaid",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "currentPaidMikesCommission",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "currentStillOwedMikesCommission",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "totalMikesCommissionBasedOnOverallClearErcFees",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "potentialFutureStillOwedMikesAffiliateCommission",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "amountDueToJacobBasedOnAmountRefunded",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "totalJacobsCommissionDueBasedOnFeesAlreadyPaid",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "currentPaidJacobsCommission",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "currentStillOwedJacobsCommission",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "totalJacobsCommissionBasedOnOverallClearErcFees",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "potentialFutureStillOwedJacobsAffiliateCommission",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "amountDueToTomBasedOnAmountRefunded",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "totalTomsCommissionDueBasedOnFeesAlreadyPaid",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "currentPaidTomsCommission",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "currentStillOwedTomsCommission",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "totalTomsCommissionBasedOnOverallClearErcFees",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "potentialFutureStillOwedTomsAffiliateCommission",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "currentStillOwedMainAffiliateCommission",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "amountOnFileWithTheIrsForQ2_2020",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "amountOnFileWithTheIrsForQ3_2020",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "amountOnFileWithTheIrsForQ4_2020",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "amountOnFileWithTheIrsForQ1_2021",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "amountOnFileWithTheIrsForQ2_2021",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "amountOnFileWithTheIrsForQ3_2021",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "amountOnFileWithTheIrsForQ4_2021",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "amountStillOwed",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "totalRefundsIssued",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "howMuchOfErcCreditTransferredDueToTaxLiability",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "taxLiabilityQ2_2020",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "taxLiabilityQ3_2020",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "taxLiabilityQ4_2020",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "taxLiabilityQ1_2021",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "taxLiabilityQ2_2021",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "taxLiabilityQ3_2021",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "taxLiabilityQ4_2021",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "estimatedFutureCostToSubstantiateFile",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "estimatedPastInHouseCosts",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "estimatedFlatRateToFixAFile",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "estimatedFutureInhouseCostsAt_40kPerMonthFor_5yrs",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "affiliateRefundLiabilityForAmountAlreadyPaid",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "totalCommissionsAndCosts",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "taxAttorneyProjectedCosts",
            type: "CustomFunctionFullRow",
            customFunction: numericEditor,
        },
        {
            field: "mikesRelatedCommissionPercentage",
            type: "CustomFunctionFullRow",
            customFunction: percentageEditor,
        },
        {
            field: "tomsRelatedCommissionPercentage",
            type: "CustomFunctionFullRow",
            customFunction: percentageEditor,
        },
        {
            field: "jacobsRelatedCommissionPercentage",
            type: "CustomFunctionFullRow",
            customFunction: percentageEditor,
        },
        {
            field: "subAffiliatePercentage",
            type: "CustomFunctionFullRow",
            customFunction: percentageEditor,
        },
        {
            field: "totalCommissionPercentage",
            type: "CustomFunctionFullRow",
            customFunction: percentageEditor,
        },

        {
            field: "mainAffiliatePercentage",
            type: "CustomFunctionFullRow",
            customFunction: percentageEditor,
        },
        {
            field: "feePercentage",
            type: "CustomFunctionFullRow",
            customFunction: percentageEditor,
        },
    ];

    return (
        <div>
            <PageDescription
                title="ERC Entities"
                description="ERC entities are the entities that are created by the ERC protocol."
                buttons={[
                    {
                        label: "Manage Deal Tag Options",
                        type: "primary",
                        onClick: () => setShowDealTagManager(true),
                    },

                    {
                        label: showAllFields ? "Do Not Show All Fields" : "Show All Fields",
                        type: showAllFields ? "danger" : "warning",
                        onClick: () => setShowAllFields(!showAllFields),
                    },
                    {
                        label: showBasicFields ? "Hide Basic Fields" : "Show Basic Fields",
                        type: showBasicFields ? "danger" : "warning",
                        onClick: () => setShowBasicFields(!showBasicFields),
                    },
                    {
                        label: showRefunds ? "Hide Refunds" : "Show Refunds",
                        type: showRefunds ? "danger" : "info",
                        onClick: () => setShowRefunds(!showRefunds),
                    },
                    {
                        label: showReceived ? "Hide Received" : "Show Received",
                        type: showReceived ? "danger" : "info",
                        onClick: () => setShowReceived(!showReceived),
                    },
                    {
                        label: showAmountFiled ? "Hide Amount Filed" : "Show Amount Filed",
                        type: showAmountFiled ? "danger" : "info",
                        onClick: () => setShowAmountFiled(!showAmountFiled),
                    },
                    {
                        label: showDidIrsIssueRefund ? "Hide Did IRS Issue Refund" : "Show Did IRS Issue Refund",
                        type: showDidIrsIssueRefund ? "danger" : "info",
                        onClick: () => setShowDidIrsIssueRefund(!showDidIrsIssueRefund),
                    },
                    {
                        label: showOwedMainAndSubAffiliateCommission
                            ? "Hide Owed Main And Sub Affiliate Info"
                            : "Show Owed Main And Sub Affiliate Info",
                        type: showOwedMainAndSubAffiliateCommission ? "danger" : "info",
                        onClick: () => setShowOwedMainAndSubAffiliateCommission(!showOwedMainAndSubAffiliateCommission),
                    },
                    {
                        label: showOwedMainAndSubAffiliateCommissionPayLater
                            ? "Hide Owed Main And Sub Affiliate Info (Pay Later)"
                            : "Show Owed Main And Sub Affiliate Info (Pay Later)",
                        type: showOwedMainAndSubAffiliateCommissionPayLater ? "danger" : "info",
                        onClick: () =>
                            setShowOwedMainAndSubAffiliateCommissionPayLater(
                                !showOwedMainAndSubAffiliateCommissionPayLater,
                            ),
                    },
                    {
                        label: showAffiliateInfo ? "Hide Affiliate Info" : "Show Affiliate Info",
                        type: showAffiliateInfo ? "danger" : "info",
                        onClick: () => setShowAffiliateInfo(!showAffiliateInfo),
                    },
                    {
                        label: showAmountOnFile ? "Hide Amount On File With IRS" : "Show Amount On File With IRS",
                        type: showAmountOnFile ? "danger" : "info",
                        onClick: () => setShowAmountOnFile(!showAmountOnFile),
                    },
                    {
                        label: showIssues ? "Hide Issues On Transcripts" : "Show Issues On Transcripts",
                        type: showIssues ? "danger" : "info",
                        onClick: () => setShowIssues(!showIssues),
                    },
                    {
                        label: showCpaTaxAttorneyDetails
                            ? "Hide CPA/Tax Attorney Details"
                            : "Show CPA/Tax Attorney Details",
                        type: showCpaTaxAttorneyDetails ? "danger" : "info",
                        onClick: () => setShowCpaTaxAttorneyDetails(!showCpaTaxAttorneyDetails),
                    },

                    {
                        label: showOtherCostsStillOwed ? "Hide Other Costs Still Owed" : "Show Other Costs Still Owed",
                        type: showOtherCostsStillOwed ? "danger" : "info",
                        onClick: () => setShowOtherCostsStillOwed(!showOtherCostsStillOwed),
                    },
                    {
                        label: showCosts ? "Hide Costs" : "Show Costs",
                        type: showCosts ? "danger" : "info",
                        onClick: () => setShowCosts(!showCosts),
                    },
                    {
                        label: showQ3Info ? "Hide Q3 Info" : "Show Q3 Info",
                        type: showQ3Info ? "danger" : "info",
                        onClick: () => setShowQ3Info(!showQ3Info),
                    },
                    {
                        label: showContracts ? "Hide Contract Info" : "Show Contract Info",
                        type: showContracts ? "danger" : "info",
                        onClick: () => setShowContracts(!showContracts),
                    },
                    {
                        label: showTaxLiabilities ? "Hide Tax Liabilities" : "Show Tax Liabilities",
                        type: showTaxLiabilities ? "danger" : "info",
                        onClick: () => setShowTaxLiabilities(!showTaxLiabilities),
                    },
                ]}
            />

            {/* <PrintPre>{dealsQuery.data}</PrintPre> */}
            <div
                className={
                    "bg-gradient-to-br to-slate-100 from-slate-200 dark:from-slate-900 " +
                    " dark:to-gray-800 border border-white rounded-xl  dark:bg-gray-800 " +
                    " dark:border-gray-700 dark:shadow-inner min-h-32 grid grid-cols-1 " +
                    " content-center shadow mb-5 p-5"
                }
            >
                <div className="flex flex-row gap-3">
                    {reportType && (
                        <ButtonNeoGen
                            onClick={() => {
                                resetFilters();
                                if (reportType === ReportTypes.BasicLiabilityReporting) {
                                    setShowBasicFields(true);
                                    setShowIssues(false);
                                } else if (reportType === ReportTypes.PayLaterDeals) {
                                    setShowBasicFields(true);
                                    setShowIssues(false);
                                } else if (reportType === ReportTypes.BadDeals) {
                                    setShowBasicFields(true);
                                    setShowIssues(false);
                                } else if (reportType === ReportTypes.PEO) {
                                    setShowBasicFields(true);
                                    setShowIssues(false);
                                }
                                setReportType(undefined);
                                setFieldChecks({});
                            }}
                            type="danger"
                        >
                            Clear Report - {getReportTypeName(reportType)}
                        </ButtonNeoGen>
                    )}
                    {/* <div> */}
                    <ButtonNeoGen
                        onClick={() => {
                            setReportType(undefined);
                            resetFilters();
                            setShowReportModal(true);
                        }}
                    >
                        Select Report
                    </ButtonNeoGen>

                    <ButtonNeoGen
                        onClick={() => {
                            console.log("clicked");
                        }}
                    >
                        Show Chart
                    </ButtonNeoGen>
                    <ButtonNeoGen
                        type={showReportDebug ? "danger" : "info"}
                        onClick={() => {
                            setShowReportDebug(!showReportDebug);
                        }}
                    >
                        {showReportDebug ? "Hide Report Debug" : "Show Report Debug"}
                    </ButtonNeoGen>

                    <CSVLink
                        headers={headers.filter((h) => {
                            if (!ignoreFields.includes(h.key)) {
                                return true;
                            }
                            return false;
                        })}
                        data={(entries ?? []).map((e) => {
                            // Remove filtered fields
                            const filteredFields = Object.keys(e).filter(
                                (i) => !ignoreFields.includes(i) && i !== "id",
                            );
                            const newObject: { [key: string]: any } = {};
                            filteredFields.forEach((key) => {
                                const formatter = formatters.find((f) => f.field === key);
                                if (formatter?.type === "DealTag") {
                                    let tags = "";
                                    dealTagsQuery.data
                                        ?.filter((i) => {
                                            const d = dealTagOptionsQuery.data?.find((j) => j.id === i.tagId);
                                            // console.log(d);
                                            return i.dealId === e.id && d?.fieldName === key;
                                        })
                                        .map((i) => {
                                            const tag = dealTagOptionsQuery.data?.find((j) => j.id === i.tagId);
                                            // console.log({ tag });
                                            tags += tag?.name.trim() + ", ";
                                        });
                                    newObject[key] = tags.substring(0, tags.length - 2);
                                } else {
                                    newObject[key] = e[key];
                                }
                            });
                            if (reportType === ReportTypes.BasicLiabilityReporting) {
                                newObject["Total Liability For 5 Years"] = newObject["amountPaid"];
                                const q2_2020_issues_tags = getDealTagTextForDealAndField(
                                    e.id,
                                    "anyIssuesWithQ2_2020IrsTranscripts",
                                );

                                if (
                                    !q2_2020_issues_tags?.some((i) =>
                                        ["no", "peo", "did not file", "waiting on transcripts"].includes(i ?? "xxyz"),
                                    ) &&
                                    (q2_2020_issues_tags?.length ?? 0) > 0
                                ) {
                                    newObject["Q2 2020 Disallowance"] = newObject["q2_2020_941XAmountFiled"];
                                } else {
                                    newObject["Q2 2020 Disallowance"] = "$0.00";
                                }

                                const q3_2020_issues_tags = getDealTagTextForDealAndField(
                                    e.id,
                                    "issuesWithQ3_2020IrsTranscripts",
                                );

                                if (
                                    !q3_2020_issues_tags?.some((i) =>
                                        ["no", "peo", "did not file", "waiting on transcripts"].includes(i ?? "xxyz"),
                                    ) &&
                                    (q3_2020_issues_tags?.length ?? 0) > 0
                                ) {
                                    newObject["Q3 2020 Disallowance"] = newObject["q3_2020_941XAmountFiled"];
                                } else {
                                    newObject["Q3 2020 Disallowance"] = "$0.00";
                                }

                                const q4_2020_issues_tags = getDealTagTextForDealAndField(
                                    e.id,
                                    "anyIssuesWithQ4_2020IrsTranscripts",
                                );

                                if (
                                    !q4_2020_issues_tags?.some((i) =>
                                        ["no", "peo", "did not file", "waiting on transcripts"].includes(i ?? "xxyz"),
                                    ) &&
                                    (q4_2020_issues_tags?.length ?? 0) > 0
                                ) {
                                    newObject["Q4 2020 Disallowance"] = newObject["q4_2020_941XAmountFiled"];
                                } else {
                                    newObject["Q4 2020 Disallowance"] = "$0.00";
                                }

                                const q1_2021_issues_tags = getDealTagTextForDealAndField(
                                    e.id,
                                    "anyIssuesWithQ1_2021IrsTranscripts",
                                );

                                if (
                                    !q1_2021_issues_tags?.some((i) =>
                                        ["no", "peo", "did not file", "waiting on transcripts"].includes(i ?? "xxyz"),
                                    ) &&
                                    (q1_2021_issues_tags?.length ?? 0) > 0
                                ) {
                                    newObject["Q1 2021 Disallowance"] = newObject["q1_2021_941XAmountFiled"];
                                } else {
                                    newObject["Q1 2021 Disallowance"] = "$0.00";
                                }

                                const q2_2021_issues_tags = getDealTagTextForDealAndField(
                                    e.id,
                                    "anyIssuesWithQ2_2021IrsTranscripts",
                                );

                                if (
                                    !q2_2021_issues_tags?.some((i) =>
                                        ["no", "peo", "did not file", "waiting on transcripts"].includes(i ?? "xxyz"),
                                    ) &&
                                    (q2_2021_issues_tags?.length ?? 0) > 0
                                ) {
                                    newObject["Q2 2021 Disallowance"] = newObject["q2_2021_941XAmountFiled"];
                                } else {
                                    newObject["Q2 2021 Disallowance"] = "$0.00";
                                }

                                const q3_2021_issues_tags = getDealTagTextForDealAndField(
                                    e.id,
                                    "anyIssuesWithQ3_2021IrsTranscripts",
                                );

                                if (
                                    !q3_2021_issues_tags?.some((i) =>
                                        ["no", "peo", "did not file", "waiting on transcripts"].includes(i ?? "xxyz"),
                                    ) &&
                                    (q3_2021_issues_tags?.length ?? 0) > 0
                                ) {
                                    newObject["Q3 2021 Disallowance"] = newObject["q3_2021_941XAmountFiled"];
                                } else {
                                    newObject["Q3 2021 Disallowance"] = "$0.00";
                                }

                                const q4_2021_issues_tags = getDealTagTextForDealAndField(
                                    e.id,
                                    "anyIssuesWithQ4_2021IrsTranscripts",
                                );

                                if (
                                    !q4_2021_issues_tags?.some((i) =>
                                        ["no", "peo", "did not file", "waiting on transcripts"].includes(i ?? "xxyz"),
                                    ) &&
                                    (q4_2021_issues_tags?.length ?? 0) > 0
                                ) {
                                    newObject["Q4 2021 Disallowance"] = newObject["q4_2021_941XAmountFiled"];
                                } else {
                                    newObject["Q4 2021 Disallowance"] = "$0.00";
                                }
                            } else if (
                                reportType === ReportTypes.PayLaterDeals ||
                                reportType === ReportTypes.MoneyStillOwedOnDealsWeHaveBeenPaidOn
                            ) {
                                newObject["Total Liability For 5 Years"] = newObject["amountPaid"];

                                const q2_2020_issues_tags = getDealTagTextForDealAndField(
                                    e.id,
                                    "anyIssuesWithQ2_2020IrsTranscripts",
                                );

                                if (
                                    !q2_2020_issues_tags?.some((i) =>
                                        ["no", "peo", "did not file", "waiting on transcripts"].includes(i ?? "xxyz"),
                                    ) &&
                                    (q2_2020_issues_tags?.length ?? 0) > 0
                                ) {
                                    newObject["Q2 2020 Disallowance"] = newObject["q2_2020_941XAmountFiled"];
                                } else {
                                    newObject["Q2 2020 Disallowance"] = "$0.00";
                                }

                                const q3_2020_issues_tags = getDealTagTextForDealAndField(
                                    e.id,
                                    "issuesWithQ3_2020IrsTranscripts",
                                );

                                if (
                                    !q3_2020_issues_tags?.some((i) =>
                                        ["no", "peo", "did not file", "waiting on transcripts"].includes(i ?? "xxyz"),
                                    ) &&
                                    (q3_2020_issues_tags?.length ?? 0) > 0
                                ) {
                                    newObject["Q3 2020 Disallowance"] = newObject["q3_2020_941XAmountFiled"];
                                } else {
                                    newObject["Q3 2020 Disallowance"] = "$0.00";
                                }

                                const q4_2020_issues_tags = getDealTagTextForDealAndField(
                                    e.id,
                                    "anyIssuesWithQ4_2020IrsTranscripts",
                                );

                                if (
                                    !q4_2020_issues_tags?.some((i) =>
                                        ["no", "peo", "did not file", "waiting on transcripts"].includes(i ?? "xxyz"),
                                    ) &&
                                    (q4_2020_issues_tags?.length ?? 0) > 0
                                ) {
                                    newObject["Q4 2020 Disallowance"] = newObject["q4_2020_941XAmountFiled"];
                                } else {
                                    newObject["Q4 2020 Disallowance"] = "$0.00";
                                }

                                const q1_2021_issues_tags = getDealTagTextForDealAndField(
                                    e.id,
                                    "anyIssuesWithQ1_2021IrsTranscripts",
                                );

                                if (
                                    !q1_2021_issues_tags?.some((i) =>
                                        ["no", "peo", "did not file", "waiting on transcripts"].includes(i ?? "xxyz"),
                                    ) &&
                                    (q1_2021_issues_tags?.length ?? 0) > 0
                                ) {
                                    newObject["Q1 2021 Disallowance"] = newObject["q1_2021_941XAmountFiled"];
                                } else {
                                    newObject["Q1 2021 Disallowance"] = "$0.00";
                                }

                                const q2_2021_issues_tags = getDealTagTextForDealAndField(
                                    e.id,
                                    "anyIssuesWithQ2_2021IrsTranscripts",
                                );

                                if (
                                    !q2_2021_issues_tags?.some((i) =>
                                        ["no", "peo", "did not file", "waiting on transcripts"].includes(i ?? "xxyz"),
                                    ) &&
                                    (q2_2021_issues_tags?.length ?? 0) > 0
                                ) {
                                    newObject["Q2 2021 Disallowance"] = newObject["q2_2021_941XAmountFiled"];
                                } else {
                                    newObject["Q2 2021 Disallowance"] = "$0.00";
                                }

                                const q3_2021_issues_tags = getDealTagTextForDealAndField(
                                    e.id,
                                    "anyIssuesWithQ2_2020IrsTranscripts",
                                );

                                if (
                                    !q3_2021_issues_tags?.some((i) =>
                                        ["no", "peo", "did not file", "waiting on transcripts"].includes(i ?? "xxyz"),
                                    ) &&
                                    (q3_2021_issues_tags?.length ?? 0) > 0
                                ) {
                                    newObject["Q3 2021 Disallowance"] = newObject["q3_2021_941XAmountFiled"];
                                } else {
                                    newObject["Q3 2021 Disallowance"] = "$0.00";
                                }

                                const q4_2021_issues_tags = getDealTagTextForDealAndField(
                                    e.id,
                                    "anyIssuesWithQ4_2021IrsTranscripts",
                                );

                                if (
                                    !q4_2021_issues_tags?.some((i) =>
                                        ["no", "peo", "did not file", "waiting on transcripts"].includes(i ?? "xxyz"),
                                    ) &&
                                    (q4_2021_issues_tags?.length ?? 0) > 0
                                ) {
                                    newObject["Q4 2021 Disallowance"] = newObject["q4_2021_941XAmountFiled"];
                                } else {
                                    newObject["Q4 2021 Disallowance"] = "$0.00";
                                }
                            }
                            // console.log(newObject);
                            return newObject;
                        })}
                        filename={"erc-entities.csv"}
                        className="bg-blue-500 hover:bg-blue-700 text-white  py-2 px-4 rounded"
                    >
                        Download CSV
                    </CSVLink>
                    {/* </div> */}
                </div>
                {showReportDebug && (
                    <div>
                        <PrintPre>{fieldChecks}</PrintPre>
                    </div>
                )}
            </div>
            <TableNeogen sticky={true} entries={entries ?? []} formatters={formatters} ignoreFields={ignoreFields} />

            <ModalDialog
                show={showDealTagManager}
                close={() => setShowDealTagManager(false)}
                size="lg"
                title="Deal Tag Manager"
                showOk={false}
                cancelText="Close"
                okAction={() => {
                    setShowDealTagManager(false);
                }}
            >
                <div>
                    <SelectNeoGen
                        label="Field Name"
                        options={(
                            Object.keys(dealsQuery.data?.[0] ?? {}).map((key) => {
                                return { name: key, id: key };
                            }) ?? []
                        ).sort((a, b) => a.name.localeCompare(b.name))}
                        value={currentFieldName}
                        onChange={(e) => {
                            setCurrentFieldName(e.toString());
                        }}
                    />
                    {currentFieldName && (
                        <div className="mt-5 grid grid-cols-2 items-end">
                            <InputControlled
                                value={newTagName}
                                label="New Tag Name"
                                onChange={(e) => {
                                    setNewTagName(e);
                                }}
                            />
                            <ButtonNeoGen
                                className="mb-5 ml-5"
                                onClick={async () => {
                                    await dealTagOptionsService.create({
                                        fieldName: currentFieldName,
                                        name: newTagName,
                                    });
                                    await queryClient.invalidateQueries(["deal-tag-options"]);
                                    setNewTagName("");
                                }}
                            >
                                Add Tag
                            </ButtonNeoGen>
                        </div>
                    )}
                    <div className="text-gray-400 text-sm block">Existing Tags</div>
                    <div className="mt-5 flex gap-2 flex-wrap">
                        {(dealTagOptionsQuery.data?.filter((d) => d.fieldName === currentFieldName) ?? [])
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((d) => {
                                return (
                                    <span
                                        key={d.id}
                                        className="inline-flex items-center gap-x-0.5 rounded-md bg-gray-50 px-2 py-1 text-sm font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10"
                                    >
                                        {d.name}
                                        <button
                                            type="button"
                                            className="group relative -mr-1 size-3.5 rounded-sm hover:bg-gray-500/20"
                                            onClick={async () => {
                                                console.log(d);
                                                await dealTagOptionsService.deleteByID(d.id);
                                                await queryClient.invalidateQueries(["deal-tag-options"]);
                                            }}
                                        >
                                            <span className="sr-only">Remove</span>
                                            <i className="fal fa-times" />
                                            <span className="absolute -inset-1" />
                                        </button>
                                    </span>
                                );
                            })}
                    </div>
                </div>
            </ModalDialog>
            <ModalDialog
                show={showReportModal}
                close={() => setShowReportModal(false)}
                size="xl"
                title="Report"
                showOk={false}
            >
                Select Report
                <div className="flex flex-row gap-3">
                    <ButtonNeoGen
                        onClick={() => {
                            resetFilters();
                            setReportType(ReportTypes.BasicLiabilityReporting);
                            setShowReportModal(false);
                        }}
                    >
                        {getReportTypeName(ReportTypes.BasicLiabilityReporting)}
                    </ButtonNeoGen>
                    <ButtonNeoGen
                        onClick={() => {
                            resetFilters();
                            setReportType(ReportTypes.PayLaterDeals);
                            setShowReportModal(false);
                            setShowCalculatedPotentialIncome(true);
                        }}
                    >
                        {getReportTypeName(ReportTypes.PayLaterDeals)}
                    </ButtonNeoGen>
                    <ButtonNeoGen
                        onClick={() => {
                            resetFilters();
                            setReportType(ReportTypes.BadDeals);
                            setShowReportModal(false);
                            setShowBasicFields(true);
                        }}
                    >
                        {getReportTypeName(ReportTypes.BadDeals)}
                    </ButtonNeoGen>
                    <ButtonNeoGen
                        onClick={() => {
                            resetFilters();
                            setReportType(ReportTypes.PEO);
                            setShowReportModal(false);
                            setShowBasicFields(true);
                        }}
                    >
                        {getReportTypeName(ReportTypes.PEO)}
                    </ButtonNeoGen>
                    <ButtonNeoGen
                        onClick={() => {
                            resetFilters();
                            setReportType(ReportTypes.MoneyStillOwedOnDealsWeHaveBeenPaidOn);
                            setShowReportModal(false);
                            setShowBasicFields(true);
                        }}
                    >
                        {getReportTypeName(ReportTypes.MoneyStillOwedOnDealsWeHaveBeenPaidOn)}
                    </ButtonNeoGen>
                </div>
            </ModalDialog>
            <ModalDialog close={() => setShowReportPDF(false)} show={showReportPDF} title={""} size="xl">
                <CSVDownload data={csvData} target="_blank" />;
            </ModalDialog>
        </div>
    );
}

export default ErcEntities;
