import { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { BellIcon, Bars3CenterLeftIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { useRecoilState } from "recoil";
import userAtom, { assumedUserAtom } from "../../atoms/userAtom";
import { ClearERCUser } from "../../typings/api/clear-erc-user";
import { getAuthToken, getAuthTokenNoThrow } from "../../services/auth-header";
import Avatar from "react-avatar";
import MessagesOverlay from "./messages-overlay";
import authService from "../../services/auth.service";
import ButtonNeoGen from "../../layout/button-neogen";
import { usePromise } from "../../jason-proof-of-concept/shared/hooks";
import { useInterval } from "react-use";
import { getAlerts } from "../../alerts/actions/get-alerts";
import UserCompanyPicker from "../../layout/user-company-picker";
import { useQueryClient } from "@tanstack/react-query";
import { authAsUser } from "../../jason-proof-of-concept/users/actions/authAsUser";
import { useUsers } from "../../jason-proof-of-concept/users/hooks/use-users";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

type DashboardHeaderProps = {
    setSideBarOpen: (open: boolean) => void;
    sideBarOpen: boolean;
    children?: React.ReactNode;
    darkMode: boolean;
};

export function compare(a: ClearERCUser, b: ClearERCUser) {
    if (a.email.toLowerCase() < b.email.toLowerCase()) {
        return -1;
    }
    if (a.email.toLowerCase() > b.email.toLowerCase()) {
        return 1;
    }
    return 0;
}

export default function DashboardHeader(props: DashboardHeaderProps) {
    const [user, setUser] = useRecoilState<ClearERCUser>(userAtom);
    const [showUserModal, setShowUserModal] = useState(false);
    const [assumedUser, setAssumedUser] = useRecoilState(assumedUserAtom);
    const [showMessages, setShowMessages] = useState(false);
    const [unreadCount, setUnreadCount] = useState(0);
    const [canAssume, setCanAssume] = useState(false);
    const [haveResettableUser, setHaveResettableUser] = useState(localStorage.getItem("haveResettableUser") === "1");
    const authToken = getAuthTokenNoThrow() || "no-auth";
    const cache = useQueryClient();
    const usersQuery = useUsers({ authToken });

    async function login(user: ClearERCUser) {
        try {
            const rbac_response = await authService.API.getURL(`users/${user.id}/roles`);
            authService.roles = rbac_response?.data;
            authService.roles.push({ roleCode: "URL_LOGOUT" }); // Everyone can log out
            authService.roles.push({ roleCode: "URL_DASHBOARD" }); // Everyone can log out
            authService.user = user;
            localStorage.setItem("userId", user?.id ?? "-1");
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("roles", JSON.stringify(authService.roles));
            setUser(user);
            window.location.href = "/dashboard";
        } catch (error: any) {
            console.error(error);
        }
    }

    useEffect(() => {
        async function fetchUsers() {
            // TODO: this doesn't fetch users
            const [canViewAdminDashboard, isAffiliate, isAccountant] = await Promise.all([
                authService.canIAccess("ADMIN_DASHBOARD"),
                authService.canIAccess("AFFILIATE"),
                authService.canIAccess("ACCOUNTANT"),
            ]);
            setCanAssume(canViewAdminDashboard || isAffiliate || isAccountant);
        }
        fetchUsers();
    }, [user]);

    const appAlertsQuery = usePromise(async () => {
        if (!user.id) {
            throw new Error("User not found");
        }
        const appAlerts = await getAlerts({
            authToken,
            filters: {
                where: {
                    userId: user.id,
                    channel: "app",
                },
            },
        });
        return appAlerts;
    }, []);

    const appAlerts = appAlertsQuery.result;

    // useInterval(() => {
    //     appAlertsQuery.execute();
    // }, 10000);

    useLayoutEffect(() => {
        const unreadMessages = appAlerts?.filter((a) => a.status === "unread") ?? [];
        setUnreadCount(unreadMessages.length);
    }, [appAlerts, user.id]);

    // function haveResettableUser(): boolean {
    // return true;
    // }
    function resetUser() {
        const items = { ...localStorage };
        for (const key in items) {
            // alert("Testing key: " + key + "   ");//
            if (!key.startsWith("RESETTER")) {
                // alert("Skipping key: " + key + "   ");
                continue;
            }
            // alert("Setting key: " + key.substring(8) + "   ");
            localStorage.setItem(key.substring(8), items[key]);
            localStorage.removeItem(key);
        }
        localStorage.setItem("haveResettableUser", "0");
        window.location.reload();
    }

    return (
        <>
            {showUserModal && (
                <UserCompanyPicker
                    userList={(usersQuery?.data as ClearERCUser[]) || []}
                    showUsers={true}
                    showCompanies={false}
                    open={showUserModal}
                    setOpen={setShowUserModal}
                    darkMode={props.darkMode}
                    setUser={(selectedUser) => {
                        async function becomeUser() {
                            if (!selectedUser.id) {
                                throw new Error("No person id");
                            }
                            const token = getAuthToken();
                            const authResponse = await authAsUser({
                                authToken: token,
                                id: selectedUser.id,
                            });
                            const userSelected = selectedUser;
                            if (userSelected) {
                                userSelected.token = authResponse.token;
                                const items = { ...localStorage };
                                for (const key in items) {
                                    if (key === "REACT_QUERY_OFFLINE_CACHE") {
                                        continue;
                                    }
                                    localStorage.setItem("RESETTER" + key, items[key]);
                                }
                                setUser(selectedUser);
                                localStorage.setItem("haveResettableUser", "1");
                                setHaveResettableUser(true);
                                await cache.clear();
                                await login(userSelected);
                            }
                        }
                        becomeUser();
                    }}
                />
            )}
            <div className="relative z-10 flex-shrink-0 flex h-16 bg-indigo-700 dark:bg-gray-800   border-b border-gray-200 dark:border-gray-900 lg:border-none ">
                <button
                    className="px-4 border-r border-gray-200 dark:border-gray-900 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden"
                    onClick={() => props.setSideBarOpen(true)}
                >
                    <span className="sr-only">Open sidebar</span>
                    <Bars3CenterLeftIcon className="h-6 w-6" aria-hidden="true" />
                </button>
                {/* Search bar */}
                <div className="flex-1 px-4 flex justify-between sm:px-6 ">
                    {/* <div className="flex-1 flex my-auto text-white">
                            <div className="w-full">
                            Stage: {position.stageId}
                            </div>
                            <div className="w-full">
                            Step: {position.stepId}
                            </div>
                        </div> */}
                    <div className="flex-1 flex py-3">
                        <span className="flex w-full sm:max-w-md">
                            {/* <PrintPre>{haveResettableUser}</PrintPre> */}
                            {haveResettableUser && (
                                <ButtonNeoGen
                                    icon="fa fa-recycle"
                                    type="primary"
                                    onClick={() => {
                                        resetUser();
                                        // setUser(null);
                                        // setAssumedUser(null);
                                    }}
                                    // className="bg-indigo-700 dark:bg-gray-800 text-white"
                                >
                                    Reset User
                                </ButtonNeoGen>
                            )}
                            {/* {canAssume && ( */}
                            {/* <ButtonNeoGen */}
                            {/* onClick={() => { */}
                        </span>
                        {/* <form className="w-full flex md:ml-0" action="#" method="GET">
                            <label htmlFor="search_field" className="sr-only">
                                Search
                            </label>
                            <div className="relative w-full text-gray-400 focus-within:text-gray-100">
                                <div
                                    className="absolute inset-y-0 left-0 flex items-center pointer-events-none"
                                    aria-hidden="true"
                                >
                                    <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                                </div>
                                <input
                                    id="search_field"
                                    name="search_field"
                                    className="dark:bg-gray-800 bg-indigo-700 placeholder-gray-300 block w-full h-full pl-8 pr-3 py-2 border-transparent text-white dark:placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent sm:text-sm"
                                    placeholder="Search documents"
                                    type="search"
                                />
                            </div>
                        </form> */}
                    </div>
                    <div className="ml-4 flex items-center md:ml-6">
                        <div className="relative">
                            <button
                                onClick={() => {
                                    // window.location.href = "/messages";
                                    setShowMessages(true);
                                }}
                                className="bg-indigo-700 dark:bg-gray-800   p-1 rounded-full text-gray-100 dark:text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                <span className="sr-only">View notifications</span>
                                <BellIcon className="h-6 w-6" aria-hidden="true" />
                                {unreadCount > 0 && (
                                    <div className="absolute top-0 right-0 text-xs bg-red-500 rounded-full px-1 text-white">
                                        {unreadCount}
                                    </div>
                                )}
                            </button>
                        </div>
                        {/* Profile dropdown */}
                        <Menu as="div" className="ml-3 relative">
                            {({ open }) => (
                                <>
                                    <div>
                                        <Menu.Button className="max-w-xs bg-indigo-700  dark:bg-gray-800  rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 lg:p-2 lg:rounded-md lg:hover:bg-indigo-500 dark:hover:bg-indigo-800">
                                            <div className="h-8 w-8 flex-shrink-0 mr-2">
                                                <Avatar
                                                    size="32px"
                                                    name={user.firstName as string}
                                                    round={true}
                                                    email={user.email}
                                                    color={"#61baf3"}
                                                />
                                            </div>
                                            <span className="hidden ml-3 text-gray-200 dark:text-green-600 text-sm font-medium lg:block shadow-inner">
                                                <span className="sr-only">Open user menu for </span>
                                                {(assumedUser || user).firstName} {!!assumedUser && "(Masquerading)"}
                                            </span>
                                            <ChevronDownIcon
                                                className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
                                                aria-hidden="true"
                                            />
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        show={open}
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items
                                            static
                                            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white dark:bg-gray-800    ring-1 ring-black ring-opacity-5 focus:outline-none"
                                        >
                                            {/* <Menu.Item>
                            {({ active }) => (
                                <button

                                className={classNames(
                                    active ? "bg-slate-200" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                    )}
                                    >
                                    Your Profile
                                    </button>
                                    )}
                                    </Menu.Item>
                                    <Menu.Item>
                                    {({ active }) => (
                                        <button

                                        className={classNames(
                                            active ? "bg-slate-200" : "",
                                            "block px-4 py-2 text-sm text-gray-700"
                                            )}
                                            >
                                            Settings
                                            </button>
                                            )}
                                        </Menu.Item> */}
                                            {assumedUser && (
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <span
                                                            onClick={() => setAssumedUser(undefined)}
                                                            className={classNames(
                                                                "cursor-pointer",
                                                                active ? "bg-slate-200 dark:bg-slate-900" : "",
                                                                "block px-4 py-2 text-sm text-gray-700 dark:text-gray-400",
                                                            )}
                                                        >
                                                            Reset user
                                                        </span>
                                                    )}
                                                </Menu.Item>
                                            )}
                                            {haveResettableUser && (
                                                <ButtonNeoGen
                                                    icon="fa fa-recycle"
                                                    type="primary"
                                                    onClick={() => {
                                                        resetUser();
                                                        // setUser(null);
                                                        // setAssumedUser(null);
                                                    }}
                                                    // className="bg-indigo-700 dark:bg-gray-800 text-white"
                                                >
                                                    Reset User
                                                </ButtonNeoGen>
                                            )}
                                            {false && (
                                                <>
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <span
                                                                onClick={() => setShowUserModal(true)}
                                                                className={classNames(
                                                                    "cursor-pointer",
                                                                    active ? "bg-slate-200 dark:bg-slate-900" : "",
                                                                    "block px-4 py-2 text-sm text-gray-700 dark:text-gray-400",
                                                                )}
                                                            >
                                                                Become user
                                                            </span>
                                                        )}
                                                    </Menu.Item>
                                                </>
                                            )}
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        href="/logout"
                                                        className={classNames(
                                                            "cursor-pointer",
                                                            active ? "bg-slate-200 dark:bg-slate-900" : "",
                                                            "block px-4 py-2 text-sm text-gray-700 dark:text-gray-400",
                                                        )}
                                                    >
                                                        Logout
                                                    </a>
                                                )}
                                            </Menu.Item>
                                        </Menu.Items>
                                    </Transition>
                                </>
                            )}
                        </Menu>
                    </div>
                </div>
            </div>
            <div className="absolute top-0 bottom-0 right-0 pt-16 lg:rounded-tl-xl m-0 h-screen xl:left-64 left-0 overflow-hidden">
                <div className="py-5 px-5 w-full lg:rounded-tl-xl dark:bg-slate-900 bg-gray-200 h-full overflow-auto">
                    {props.children}
                </div>
            </div>
            <MessagesOverlay show={showMessages} setShow={setShowMessages} alertsQuery={appAlertsQuery} />
        </>
    );
}
