import APIService from "./api.service";

class DealsService extends APIService<Deal> {
    constructor() {
        super("/deals");
    }
}

export type Deal = {
    id?: number;
    companyName: string;
    isAPeo: string;
    dealPayType: string;
    feePercentage: string;
    dealPayStatus: string;
    wasTheDealFinanced: string;
    didTheIrsIssueARefundFullPartialOrNone: string;
    clientRefundAmountBasedOn_941XsBeforeInterest: string;
    clearErcFees: string;
    amountPaid: string;
    amountStillOwed: string;
    q2_2020_941XAmountFiled: string;
    didIrsRecieveQ2_2020: string;
    anyIssuesWithQ2_2020IrsTranscripts: string;
    amountOnFileWithTheIrsForQ2_2020: string;
    didTheIrsIssueARefundForQ2_2020: string;
    amountOfRefundIssuedForQ2_2020: string;
    q3_2020_941XAmountFiled: string;
    didIrsRecieveQ3_2020: string;
    issuesWithQ3_2020IrsTranscripts: string;
    amountOnFileWithTheIrsForQ3_2020: string;
    didTheIrsIssueARefundForQ3_2020: string;
    amountOfRefundIssuedForQ3_2020: string;
    q4_2020_941XAmountFiled: string;
    didIrsRecieveQ4_2020: string;
    anyIssuesWithQ4_2020IrsTranscripts: string;
    amountOnFileWithTheIrsForQ4_2020: string;
    didTheIrsIssueARefundForQ4_2020: string;
    amountOfRefundIssuedForQ4_2020: string;
    q1_2021_941XAmountFiled: string;
    didIrsRecieveQ1_2021: string;
    anyIssuesWithQ1_2021IrsTranscripts: string;
    amountOnFileWithTheIrsForQ1_2021: string;
    didTheIrsIssueARefundForQ1_2021: string;
    amountOfRefundIssuedForQ1_2021: string;
    q2_2021_941XAmountFiled: string;
    didIrsRecieveQ2_2021: string;
    anyIssuesWithQ2_2021IrsTranscripts: string;
    amountOnFileWithTheIrsForQ2_2021: string;
    didTheIrsIssueARefundForQ2_2021: string;
    amountOfRefundIssuedForQ2_2021: string;
    didWeFileQ3_941X: string;
    q3_2021_941XAmountFiled: string;
    didIrsRecieveQ3_2021: string;
    anyIssuesWithQ3_2021IrsTranscripts: string;
    amountOnFileWithTheIrsForQ3_2021: string;
    didTheIrsIssueARefundForQ3_2021: string;
    amountOfRefundIssuedForQ3_2021: string;
    wasThereAQ3Disallowance: string;
    amountStillOwedWithoutQ3: string;
    q3MoneyThatWeMayHaveToRefund: string;
    q4_2021_941XAmountFiled: string;
    didIrsRecieveQ4_2021: string;
    anyIssuesWithQ4_2021IrsTranscripts: string;
    amountOnFileWithTheIrsForQ4_2021: string;
    didTheIrsIssueARefundForQ4_2021: string;
    amountOfRefundIssuedForQ4_2021: string;
    totalRefundsIssued: string;
    isThereATaxLiability: string;
    howMuchOfErcCreditTransferredDueToTaxLiability: string;
    isThereMonitoring: string;
    whatTypeOfMonitoring: string;
    affliateGroupTeam: string;
    affiliateOnAccount: string;
    mainAffiliateName: string;
    mainAffiliatePercentage: string;
    mainAffiliateCommissionsBasedOnAmountRefunded: string;
    totalMainAffiliateCommissionDueBasedOnFeesAlreadyPaid: string;
    currentPaidMainAffiliateCommission: string;
    currentStillOwedMainAffiliateCommission: string;
    totalMainAffiliateCommissionBasedOnOverallClearErcFees: string;
    potentialFutureStillOwedMainAffiliateCommission: string;
    subAffiliateName: string;
    subAffiliatePercentage: string;
    subAffiliateCommissionBasedOnAmountRefunded: string;
    totalSubAffiliateCommissionDueBasedOnFeesAlreadyPaid: string;
    currentPaidSubAffiliateCommission: string;
    currentStillOwedSubAffiliateCommission: string;
    totalSubAffiliateCommissionBasedOnOverallClearErcFees: string;
    potentialFutureStillOwedSubAffiliateCommission: string;
    mikesRelatedCommissionPercentage: string;
    amountDueToMikeBasedOnAmountRefunded: string;
    totalMikesCommissionDueBasedOnFeesAlreadyPaid: string;
    currentPaidMikesCommission: string;
    currentStillOwedMikesCommission: string;
    totalMikesCommissionBasedOnOverallClearErcFees: string;
    potentialFutureStillOwedMikesAffiliateCommission: string;
    jacobsRelatedCommissionPercentage: string;
    amountDueToJacobBasedOnAmountRefunded: string;
    totalJacobsCommissionDueBasedOnFeesAlreadyPaid: string;
    currentPaidJacobsCommission: string;
    currentStillOwedJacobsCommission: string;
    totalJacobsCommissionBasedOnOverallClearErcFees: string;
    potentialFutureStillOwedJacobsAffiliateCommission: string;
    tomsRelatedCommissionPercentage: string;
    amountDueToTomBasedOnAmountRefunded: string;
    totalTomsCommissionDueBasedOnFeesAlreadyPaid: string;
    currentPaidTomsCommission: string;
    currentStillOwedTomsCommission: string;
    totalTomsCommissionBasedOnOverallClearErcFees: string;
    potentialFutureStillOwedTomsAffiliateCommission: string;
    totalCommissionDue: string;
    totalPaidCommission: string;
    totalCurrentStillOwedCommission: string;
    totalCommissionBasedOnOverallClearErcFees: string;
    totalPotentialFutureStillOwedAffiliateCommission: string;
    totalCommissionPercentage: string;
    cpaOnFile: string;
    totalCpaCosts: string;
    cpaCostsPaid: string;
    cpaCostsStillOwed: string;
    totalTaxAttorneyCosts: string;
    taxAttorneyCostsPaid: string;
    taxAttorneyCostsOwed: string;
    totalOtherCosts: string;
    otherCostsPaid: string;
    otherCostsStillOwed: string;
    totalCommissionsAndCosts: string;
    netRevenueWithQ3: string;
    netRevenueAsAPercentageOfFeesWithQ3: string;
    netRevenueWithoutQ3: string;
    netRevenueAsAPercentageOfFeesWithoutQ3: string;
    isThereAContract: string;
    isThereAClawbackContract: string;
    wasThereARequestForMoneyBack: string;
    doesTheFileHaveASubstantiationPackage: string;
    estimatedFutureCostToSubstantiateFile: string;
    affiliateRefundLiabilityForAmountAlreadyPaid: string;
    cpaFutureMaintenanceAndAppealCosts: string;
    taxAttorneyProjectedCosts: string;
    estimatedFlatRateToFixAFile: string;
    estimatedPastInHouseCosts: string;
    estimatedFutureInhouseCostsAt_25kPerMonthFor_5yrs: string;
    linkToFolder: string;
};
export default new DealsService();
